

body {
    background: rgb(93,97,103);
    background: linear-gradient(0deg, #464b52 0%, #2f363e 100%);
    background-repeat: no-repeat;
    font-family: "Comfortaa", sans-serif !important;
    font-weight: 400 !important;
    min-height: 100vh;
    color: #40474D;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('fonts/Comfortaa-Regular.woff2') format('woff2'), url('fonts/Comfortaa-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('fonts/Comfortaa-Light.woff2') format('woff2'), url('fonts/Comfortaa-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Comfortaa';
    src: url('fonts/Comfortaa-Bold.woff2') format('woff2'), url('fonts/Comfortaa-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('fonts/Comfortaa-Medium.woff2') format('woff2'), url('fonts/Comfortaa-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('fonts/Comfortaa-SemiBold.woff2') format('woff2'), url('fonts/Comfortaa-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('fonts/Comfortaa-Regular.woff2') format('woff2'), url('fonts/Comfortaa-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.container {
    max-width: 1730px !important;
    margin: 0 auto !important;
}

.binguo-main {
    margin-top: 50px;
}

    .binguo-main .collapse ul li a {
        color: #fff;
        text-decoration: none;
        font-weight: 700;
        font-size: 16px;
    }

.binguo-header-main ul {
    display: flex;
    align-items: center;
}

    .binguo-header-main ul li button {
        padding: 10px 50px;
        background: #FBAB40;
        background-blend-mode: overlay, normal;
        border-radius: 5px;
        border: 0;
        box-shadow: 0px 10px 13px rgba(0, 0, 0, 0.2);
    }

    .binguo-header-main ul li a {
        color: #fff;
        text-decoration: none;
    }

    .binguo-header-main ul li {
        margin: 0px 10px;
    }

        .binguo-header-main ul li:nth-child(1) a {
            margin-right: 15px;
        }

.binguo-header-main .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: #f78f3d;
}

.login-world-map {
    margin-top: 84px;
}

    .login-world-map .row {
        align-items: center;
    }

    .login-world-map .col-md-6 h2 {
        color: #fff;
        font-weight: 700;
        margin-bottom: 20px;
        /*font-size: 45px;*/
        font-size: 35px;
    }

    .login-world-map .col-md-6 p {
        max-width: 745px;
        color: #fff;
        line-height: 30px;
        /*font-size: 20px;*/
        font-size: 16px;
    }

    .login-world-map .col-md-6 .first-para {
        margin-top: 25px;
    }

.login-world-globe {
    text-align: end;
}

.login-world-map .container {
    max-width: 1530px !important;
}

.login-world-map .container {
    max-width: 1553px !important;
}
/*Modal Login*/
.modal-login .modal-content {
    background: #F6F6F6;
}

.modal-login .modal-header {
    justify-content: flex-end;
    padding: 16px 16px 0px 16px;
}

.close-btn button {
    border: none;
    background: none;
}

    .close-btn button img {
        width: 16px;
        height: 16px;
    }

.modal-login .modal-body {
    text-align: center;
    /*padding-top: 0;*/
    padding: 0px 40px 40px 40px;
}

.modal-login .modal-header {
    border-bottom: 0;
}

.modal-login .modal-footer {
    border-top: 0;
}

.modal-body .production-language-main:not(:first-child) {
    border-top: 1px solid rgb(0 0 0 / 30%);
    padding-top: 20px;
}

.modal-body .production-language-main:first-child {
    margin-top: -10px;
}

.modal-body h2 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 15px;
    /* margin-top: -50px; */
    color: #000000;
}

.modal-body .form-main {
    width: 90%;
    margin: 0 auto;
    position: relative;
}

    .modal-body .form-main .form-control {
        padding: 0px;
        border: 1px solid #CDCDCD;
        line-height: inherit;
    }

    .modal-body .form-main ::placeholder {
        color: #000;
    }

.form-main form img {
    position: absolute;
    right: 13px;
    bottom: 12px;
    z-index: 99999;
}

.modal-login .modal-dialog {
    max-width: 550px;
}

.modal-body .forgot-pass {
    color: #61B0DC;
    font-weight: 600;
    text-align: right;
    display: inline-block;
    font-size: 14px;
}

.fp-parent {
    text-align: right;
}

.modal-body .login-button {
    background: #61B0DC;
    width: 100%;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-body p {
    font-size: 14px;
    color: #40474D;
    font-weight: 600;
    margin-top: 15px !important;
    margin-bottom: 20px !important;
}

    .modal-body p a {
        color: #61B0DC;
    }

.modal-body .send-button {
    margin-top: 0px;
    margin-bottom: 20px;
}

.modal-login .btn:first-child:active {
    border: 1px solid #61B0DC !important;
    background-color: #61B0DC !important;
}

.modal-body .enter-email {
    margin-top: 20px;
    margin-bottom: 25px;
}

.forgot-passs {
    margin-bottom: 30px;
    margin-top: 30px;
    display: block;
}

.verify-code {
    display: inline-block;
}

.verify-code-main form {
    /* display: flex;
    justify-content: center; */
    margin-bottom: 10px;
}

.verify-code-main .code-box {
    background: #fff;
    width: 50px;
    height: 50px;
    align-items: center;
    /* display: flex;
    justify-content: center; */
    border-radius: 5px;
    border: 1px solid #cdcdcd;
    color: #40474D;
    font-size: 22px;
    margin: 0px 7px;
    text-align: center
}

.verify-code-main a {
    text-decoration: none;
}

.verify-code-main form .form-control .code-box:focus-visible {
    border: 1px solid #CDCDCD;
}

.verify-code-button {
    text-decoration: none;
}

.creat-newpass {
    margin-bottom: 19px;
}

.form-main .resend-code {
    color: #61B0DC;
    font-size: 20px;
    /* text-underline-position: under; */
    margin-bottom: 10px;
    display: block;
    font-weight: 600;
    font-size: 14px;
    text-decoration: underline;
    margin-top: 30px;
}

.change-new-pass {
    margin-top: 0px !important;
}
/* .modal-body .login-button:active {
    background: #61B0DC !important;
    color: #fff !important;
} */
.create-new-pass {
    position: relative;
}

    .create-new-pass .eye-img-one {
        position: absolute;
        top: 17px;
    }

    .create-new-pass .eye-img-two {
        position: absolute;
        bottom: 17px;
    }

    .create-new-pass .eye-img-onee {
        position: absolute;
        bottom: 89px;
    }

    .create-new-pass .lesson-forms-selectors .form-select {
        background: url(../assets/images/signup-dropdown.svg) no-repeat !important;
        background-position: 96% 18px !important;
        background-color: #ffffff !important;
        border: 2px solid #cdcdcd;
        border-radius: 5px;
        padding: 10px 10px 10px 20px;
        font-size: 14px;
        color: #40474D;
    }

        .create-new-pass .lesson-forms-selectors .form-select:focus {
            box-shadow: none;
        }

.modal-body .signup-btn {
    margin-top: 7px;
}
/*Contact Us Page Starts*/
.contact-us-main {
    text-align: center;
    margin-top: 50px;
}

    .contact-us-main .container {
        max-width: 1188px !important;
    }

    .contact-us-main p {
        font-size: 16px;
        color: #fff;
        margin-top: 5px;
    }

.contactMsg p {
    margin-top: -40px;
}

.contact-heading {
    font-size: 35px;
    font-weight: 700;
    color: #fff;
}

.contactus-white-bg {
    background: #F6F6F6;
    border-radius: 10px;
    padding: 50px 100px;
    margin-top: 45px;
    margin-bottom: 115px;
}

    .contactus-white-bg h2 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 30px;
        color: #000;
    }

    .contactus-white-bg .form-label {
        color: #40474D;
    }

    .contactus-white-bg .name-email {
        width: 50%;
    }

.contact-forms .form-control {
    line-height: 2.2;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    margin-bottom: 30px;
}

.submit-button {
    width: 100%;
    margin-top: 18px;
}

    .submit-button button {
        background: #61B0DC;
        width: 100%;
        height: 50px;
        color: #fff;
        font-weight: 700;
        border: 0;
        border-radius: 5px;
        font-size: 16px;
    }

.binguo-main .navbar-toggler {
    border: 1px solid #fff;
}

.binguo-main .navbar-toggler-icon {
    filter: invert(1);
}

.dashbord-main .navbar-brand img {
    max-width: 282px;
}

.dashbord-main .white_header_bg {
    border-bottom: 1px solid rgb(255 255 255 / 25%);
    padding-bottom: 15px;
    padding-left: 0;
    padding-right: 0;
}
/*Dashbord CSS Starts Here*/
.dashbord-main {
    margin-top: 18px;
}

.dashbord-lessons-main {
    margin-bottom: 80px;
}

.my-lessons {
    display: flex;
    align-items: center !important;
}

    .my-lessons h2 {
        font-size: 35px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 0;
        margin-top: 8px;
        margin-right: 15px;
    }

.my-lessons-dropdown .dropdown-toggle {
    background: #FBAB40;
    border-radius: 50%;
    width: 50px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
}

    .my-lessons-dropdown .dropdown-toggle::after {
        display: none;
    }

    .my-lessons-dropdown .dropdown-toggle:active {
        background: #F66234;
    }

.my-lessons-dropdown {
}

    .my-lessons-dropdown .dropdown-item {
        font-weight: 700 !important;
        font-size: 14px;
        color: #40474D;
    }

    .my-lessons-dropdown .dropdown-menu {
        transform: translate(0px, 65px) !important;
        padding: 15px 11px 15px 10px;
        border-radius: 10px;
    }

.inner-container {
    max-width: 1320px !important;
    padding: 0;
}

.table-main {
    margin-top: 30px;
}

.table-inside {
    background: #F6F6F6;
    border-radius: 10px;
    padding: 30px 0px 0px 0px;
    position: relative;
}

    .table-inside::before {
        content: "";
        border-bottom: 1px solid rgb(0 0 0 / 30%);
        position: absolute;
        width: 100%;
        left: 0;
        top: 70px;
    }

.table-main table {
    width: 100%;
}

.table-inside tr th {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 9px;
    color: #000;
}

.table-inside {
    padding: 25px 35px 0px 30px;
}

    .table-inside tr td {
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 17px;
        width: 1024px;
        color: #40474D;
    }

    .table-inside tbody tr:first-child td {
        padding-top: 25px;
    }

    .table-inside tbody tr:last-child td {
        padding-bottom: 30px;
    }

    .table-inside tr td a {
        background: #61B0DC;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-left: auto;
    }

    .table-inside tr th:nth-child(1) {
        width: 47%;
        padding-left: 60px;
    }

    .table-inside tr td {
        width: 18%;
    }

.table-inside-two tr td {
    width: 18%;
}

.table-inside tbody tr td:last-child {
    text-align: right;
}

.table-image {
    width: 40px !important;
    height: 40px !important;
    background: transparent !important;
    padding: 0 !important;
    border-radius: 50%;
}

.table-inside span {
    margin-left: 20px;
    width: 80%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.a-grade {
    color: #008C46;
    font-size: 14px;
    font-weight: 600;
}

.b-grade {
    color: #2282B8;
    font-size: 14px;
    font-weight: 600;
}

.f-grade {
    color: #FF0000;
    font-size: 14px;
    font-weight: 600;
}

.empty-cell {
    color: #40474D;
    font-size: 14px;
}

.search-bar-main {
    width: 100%;
}

.dashbord-searchBar {
    position: relative;
    width: 65%;
    margin-left: auto;
}

    .dashbord-searchBar .form-control {
        min-height: 40px;
        margin-left: auto;
        height: 40px;
        padding: 5px 50px 5px 13px;
    }

    .dashbord-searchBar .floating-labels {
        top: -7px !important;
    }

    .dashbord-searchBar a {
        position: absolute;
        top: 9px;
        right: 18px;
    }

.all-lessons {
    margin-top: 30px;
}

.all-lessons-main .my-lessons-dropdown .dropdown-menu {
    transform: translate(0px, 59px) !important;
}

.lessons-row {
    align-items: center;
}

.white-box-bg {
    background: #F6F6F6;
    border-radius: 10px;
    padding: 30px 30px 30px 30px;
    margin-bottom: 30px;
    height: calc(100% - 33px);
    word-break: break-word;
}

.whiteBox-popUp {
    align-items: center;
    justify-content: unset !important;
}

.whiteBox-heading {
    display: flex;
    width: 100% !important;
    margin-top: 0px !important;
    justify-content: space-between !important;
}

    .whiteBox-heading h2 {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 0px;
        margin-top: 0;
        margin-right: 15px;
        color: #000;
        width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
    }

    .whiteBox-heading img {
        width: auto;
    }

    .whiteBox-heading .usa-flagg {
        margin-right: 20px;
    }

.usa-flagg img {
    width: auto;
    height: auto;
}

.dashbord-paraa {
    font-size: 14px;
    color: #40474D;
    margin: 20px 0px 20px 0px;
}

.est-time {
    font-size: 20px;
    margin-bottom: 20px;
    display: block;
    margin-top: 26px;
}

.des {
    font-weight: 700;
    font-size: 20px;
}

.text {
    font-size: 20px;
    margin-bottom: 30px;
    display: block;
}

.whiteBox-buttons a {
    text-decoration: none;
}

.lesson-startss a {
    background: #61B0Dc;
    padding: 38px 23px;
    width: 100px !important;
    height: 100px;
    display: flex !important;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
}

.whiteBox-buttons {
    display: flex;
    justify-content: space-between;
}

    .whiteBox-buttons .whiteBox-List ul {
        padding-left: 1rem;
    }

.whiteBox-List ul li span {
    font-size: 14px;
    color: #40474D;
}

.whiteBox-buttons .lesson-start {
    padding: 0px;
    margin-top: 22px;
}

.whiteBox-Main {
    margin-top: 30px;
}

.whiteBox-heading .blue {
    background: #265CFF;
}

.whiteBox-heading .purple {
    background: #8500B2;
}

.whiteBox-heading .orange {
    background: #D96D00;
}

.whiteBox-heading .red {
    background: #B20000;
}

.pagination-box {
}

.pagination-main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 50px;
}

    .pagination-main .dropdown-item.active, .dropdown-item:active {
        background-color: #61b0dc;
    }

.dropdown-body {
    display: flex;
    align-items: center;
}

    .dropdown-body .dropdown-toggle {
        background: #F6F6F6;
        color: #000;
        border-radius: 3px;
        padding: 4px 0px 3px 9px;
        margin: 0px 25px 0px 15px;
        font-size: 14px;
        border: 1px solid #d0d0d0;
    }

.dropdown-items .dropdown-toggle {
    background: #F6F6F6;
    color: #000;
    font-size: 14px;
}

.dropdown-body .dropdown-toggle::after {
    margin-left: 16px;
    margin-right: 6px;
}

.dropdown-body span {
    font-size: 14px;
    color: #fff;
}

.pagination-box .pagination {
    margin-bottom: 0;
    --bs-pagination-bg: rgb(246 246 246 / 15%);
}

.page-link-left {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
}

.page-link-right {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
}

.pagination-box .page-link {
    padding: 3px 10px;
    color: #40474D;
    font-size: 14px;
    background: #f6f6f6;
    border: 1px solid #D0D0D0;
}

.pagination-box .pagination li span {
    font-size: 14px;
    color: #fff;
    margin: 0px 13px;
}

.pagination-box .pagination {
    align-items: center;
}

.terminology {
    width: 53%;
    align-items: center;
}

    .terminology h2 {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 2px;
        margin-top: 0px;
    }

.whiteBox-para {
    font-weight: 600 !important;
    line-height: 1.4 !important;
}

.whiteBox-head {
    color: #000;
    font-weight: 700;
    font-size: 16px;
}

.add-suggested-lessons .modal-dialog {
    max-width: 550px !important;
}

.production-popup-main .modal-dialog {
    max-width: 800px;
}

.work-terminlogy .numbers {
    width: 50px;
    height: 50px;
}

.work-terminology-main {
    display: flex;
}

.terminology-left {
    width: 57.5%;
}

.a-plus-buttons {
    display: flex;
}

.a-plus-middle span {
    font-size: 80px;
    font-weight: 700;
    color: #008C46;
}

.i-button-image img {
    margin-bottom: 5px;
}

.i-button-image label {
    font-weight: 700;
    font-size: 22px;
    color: #000;
    margin-right: 10px;
}

.terminology-left ul {
    padding: 0px 0px 0px 5px;
    list-style-type: none;
    margin-bottom: 0;
}

.terminology-left p {
    text-align: left;
    line-height: 30px;
    max-width: 363px;
}

.terminology-left a {
    background: #61B0DC;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    max-width: 100px;
    margin-top: 44px;
    padding: 0;
}

.work-terminlogy .modal-body {
    padding: 0px 30px 30px 30px;
}

.terminology-left h2 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0;
}

.levelUp-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 2;
}

.orange-dot {
    width: 4px;
    height: 4px;
    display: inline-block;
    background: #40474D;
    border-radius: 50%;
    margin-right: 15px;
    margin-bottom: 3px;
}

.login-dropdown span {
    font-weight: 700;
    color: #FFF;
    font-size: 20px;
    margin-left: 15px;
}

.lesson-start {
    background: #F6F6F6;
    border-radius: 10px;
    padding: 20px;
}

    .lesson-start .terminology {
        margin-top: 0px;
    }

.lesson-starts {
    margin-top: 30px;
}

    .lesson-starts .terminology-left p {
        max-width: fit-content;
    }

    .lesson-starts .work-terminology-main {
        display: flex;
        justify-content: space-between;
    }

    .lesson-starts .terminology-left {
        width: 100%;
    }

    .lesson-starts .terminology-left {
    }

    .lesson-starts .modal-body p {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .lesson-starts .whiteBox-heading .numbers {
        width: 50px;
        height: 50px;
        font-size: 26px;
        font-weight: 700;
    }

    .lesson-starts .terminology {
        width: 100%;
        margin-bottom: 10px;
    }

.read-paragraph {
    margin-top: 30px;
    margin-bottom: 123px;
}

.read-para-main {
    display: flex;
}

.read-para-left {
    width: 112.5%;
    background: #f6f6f6;
    padding: 20px;
    border-radius: 10px;
    margin-right: 15px;
}

    .read-para-left a {
        display: block;
    }

.read-para-right {
    width: 78%;
    background: #f6f6f6;
    padding: 20px;
    border-radius: 10px;
    margin-left: 15px;
}

.read-para-left h2 {
    font-weight: 700;
    font-size: 22px;
}

.read-para-left p {
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 5px;
    line-height: 1.3;
    max-width: 99%;
}

.read-para-right h2 {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 0;
    color: #000;
}

.read-para-right p {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.blur-para .read-para-left p {
    text-shadow: 0 0 8px #000;
    color: transparent;
    user-select: none;
}

.blur-para .read-para-left h2 {
    text-shadow: 0 0 8px #000;
    color: transparent;
}

.read-para-left {
    position: relative;
    min-height: 130px;
}

.lesson-start-button-main {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lesson-start-btn {
    font-weight: 700;
    background: #61B0DC;
    padding: 35px 24px;
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    border-radius: 50%;
    text-transform: capitalize;
}

.stop-buttonn {
    text-align: center;
}

.stop-btn {
    width: 50px;
    height: 50px;
    background: #EF4536;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
}

    .stop-btn span {
        width: 18px;
        height: 18px;
        background: #fff;
        display: inline-block;
    }

.retest {
    max-width: 170px
}

.loading-bar-main-box {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.white-loading-box {
    width: 58.5%;
    height: 102px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 30px 20px 30px 20px;
    border-radius: 5px;
}

    .white-loading-box h2 {
        text-shadow: none !important;
        color: #40474D !important;
        font-size: 16px;
    }

    .white-loading-box .progress, .progress-stacked {
        background-color: #A0A0A0;
    }

    .white-loading-box .progress-bar {
        background-color: #5b6068;
    }

.terminology-mainBox {
    width: 100% !important;
}

.a-plus-main {
    display: flex;
    justify-content: space-between;
    position: relative;
}

    .a-plus-main .terminology {
        width: 105% !important;
        margin-bottom: 23px !important;
    }

.popup-issue {
    justify-content: unset !important;
}

    .popup-issue h2 {
        width: unset;
    }

.a-plus-main .a-plus-right .tooltip-container {
    position: absolute;
    top: 0;
    right: 0px;
}

.a-plus-right .retest {
    background: #FBAB40;
    text-decoration: none;
    padding: 2px 15px 0px 15px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-left: auto;
}

.a-plus-buttons .dashbord-retest {
    background: #61B0DC;
    margin-left: 15px;
    /*  padding: 2px 19px 0px 19px;*/
    width: 124px;
}

.terminology-right {
    width: 35%;
    border-radius: 5px;
    background: #DBDBDB !important;
    padding: 20px !important;
    margin-bottom: 20px;
}

.focusBox-right {
    text-align: unset !important;
    display: unset !important;
}

.terminology-right ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

.terminology-right h2 {
    font-size: 22px;
    margin-bottom: 10px;
}

.a-plus-right .terminology-right {
    width: 100% !important;
    background: #f6f6f6;
    padding: 30px 0px 0px 0px;
}

.a-plus-right {
    text-align: right;
    display: flex;
    align-items: flex-end;
}

    .a-plus-right .levelUp-text {
        font-size: 18px;
    }

.red {
    color: #FF0000;
}

.green {
    background-color: #62B142 !important;
}

.yellow {
    color: #E28400;
}

.production-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.read-para-right .production-main:not(:last-child) {
    border-bottom: 1px solid rgb(0 0 0 / 30%);
    padding-bottom: 5px;
}

.production-left h2 {
    font-weight: 700;
    font-size: 16px;
}

.production-image {
    display: flex;
    align-items: center;
}

.book-img {
    background: #FBAB40;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .book-img img {
        /* width: 17px;
        height: 17px;*/
        width: 24px;
        height: 24px;
    }

.production-left .production-image a img {
    width: 20px;
    height: 24px;
}

.production-left span {
    font-size: 14px;
    font-weight: 700;
    display: inline-block;
    max-width: 320px;
    line-height: 29px;
    margin-top: 5px;
}

.production-right .circle-arrows {
    background: #61B0DC;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prod-right {
    /* padding-top: 50px; */
}
/* .suggested-lesson h2 {
    font-size: 35px;
    font-weight: 700;
    color: #fff;
    margin: 30px 0px;
} */
.productions-needs {
    margin-bottom: 0px;
}

.production-popUp {
    text-align: left;
}

.pruhduhkshn-main {
    display: flex;
    align-items: center;
    margin-top: 13px;
}

.noice-speaker {
    margin-right: 15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #61B0DC;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .noice-speaker img {
        /*width: 19px;*/
        width: 23px;
    }

.pruhduhkshn-main span {
    font-size: 22px;
    font-weight: 700;
    color: #61B0DC;
}

.production-popup-main .modal-body p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.heading {
    display: block;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    margin: 17px 0px 10px 0px;
}

.descriptions span {
    display: block;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
}

.lesson-bg-gray {
    border-radius: 5px;
    background: #EAEAEA;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: left;
}
/* .traning {
    font-size: 20px;
    font-weight: 700;
    display: block;
    text-align: left;
    margin: 30px 0px 20px 0px;
} */
/* .lesson-one {
    display: flex;
    justify-content: space-between;
} */
/* .lessons-left {
    text-align: left;
} */
/* .lessons-left label {
    font-weight: 700;
    font-size: 20px;
} */
/* .lessons-left span {
    font-size: 20px;
    width: unset;
    display: inline-block;
} */
/* .box-margin {
    margin-bottom: 20px;
} */
/* .lesson-one a {
    background: #FBAB40;
    padding: 10px 40px;
    margin: 9px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    text-transform: capitalize;
} */
.lesson-seven-main {
    position: relative;
}
/*.lesson-seven-main span:nth-child(1) {
        font-size: 22px;
        font-weight: 700;
        display: block;
        text-align: left;
        margin-bottom: 25px;*/
/* margin-top: 25px; */
/*}*/
/*   .lesson-seven-main span:nth-child(2) {
        font-size: 22px;
        font-weight: 700;
        display: block;
        text-align: left;
        margin-bottom: 25px;
    }

    .lesson-seven-main span:nth-child(3) {
        font-size: 22px;
        font-weight: 700;
        display: block;
        text-align: left;
        margin-bottom: 25px;
    }

    .lesson-seven-main span:nth-child(4) {
        font-size: 22px;
        display: block;
        text-align: left;
        line-height: 30px;
        margin-bottom: 30px;
    }*/
.blur-lesson-seven span {
    text-shadow: 0 0 8px #000;
    color: transparent;
}

.blur-lesson-seven img {
    text-shadow: 0 0 8px #000;
    color: transparent;
}

.lesson-seven-main a {
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.start-button {
    background: #61B0DC;
    padding: 10px 40px;
    margin: 9px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    text-transform: capitalize;
}

.stop-buton a {
    position: unset;
}

.white-loading-modalBox h2 {
    margin-bottom: 15px;
}

.loading-modal .white-loading-box {
    top: 20%;
}

.loading-modal a {
    position: unset;
    width: 100%;
    transform: translateX(0%);
    text-decoration: none;
}

.retest-btn-main {
    display: flex;
    align-items: center;
    margin: 30px 0px 30px 0px;
}

.retest-button {
    background: #FBAB40;
    text-decoration: none;
    padding: 0px 33px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.retest-btn-main span {
    font-weight: 700;
    font-size: 28px;
    margin-right: 25px;
}

.complete-lesson {
    /* width: 46%;
    margin: 0 auto; */
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
}

    .complete-lesson a {
        background: #FBAB40;
        text-decoration: none;
        padding: 0px 45px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        text-transform: capitalize;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }

.completee {
    padding: 0px 31px !important;
    background: #61B0DC !important;
}

tbody tr td:not(:first-child) {
    text-align: left;
}

thead tr th:not(:first-child) {
    text-align: left;
}

.login-menu .dropdown-menu {
    display: none;
}

    .login-menu .dropdown-menu.show {
        display: block;
        width: 84%;
        margin-top: 10px;
        padding: 9px 0px;
        border-radius: 10px;
    }

.login-menu .dropdown-toggle::after {
    display: none;
}

.login-menu ul li a {
    color: #40474D !important;
}

.lesson-tenn .production-right .circle-arrows {
    background: #3CA34C;
    position: relative;
}

.i-button-main {
    position: relative;
}

.i-button {
    background: #F66234;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    bottom: 90px;
    left: 143px;
}

.binguo-main .navbar-toggler:focus {
    box-shadow: none;
}

.white-loading-modalBox a {
    text-decoration: none;
}

#register a {
    text-decoration: none;
}

.lesson-forms .form-floating > .form-control {
    min-height: 40px;
}

.sentence .form-floating > label {
    top: -12px;
    font-size: 12px;
}

.lesson-forms label {
    font-size: 14px;
    color: #40474D;
}

.suggested-lesson {
    margin-bottom: 55px;
}

.modal-login .modal-dialog {
    margin-top: 100px;
}

.form-main .btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
    color: #fff !important;
    border: none;
    background: #FBAB40;
}

.login-menu .dropdown-item.active, .dropdown-item:active {
    background-color: #fff;
}
/*Lessons Dashbord for Admin*/
.lessons-input-fielsMain {
    margin-top: 20px;
    position: sticky;
    top: 0px;
    z-index: 9;
    background: #353b43;
    padding: 10px 0px;
}

.password-reset-main .inner-container {
    padding: 0px 0px;
}

.lessons-input-fielsMain form {
    display: flex;
}

    .lessons-input-fielsMain form > div:nth-child(2) {
        margin: 0px 10px 0px 20px;
        /*width: 21.4%;*/
        width: 15%;
        position: relative;
    }

    .lessons-input-fielsMain form > div:nth-child(3) {
        margin: 0px 0px 0px 10px;
    }

    .lessons-input-fielsMain form > div:nth-child(4) {
        margin: 0px 10px 0px 10px;
        /*width: 15%;*/
        width: 21.4%;
    }

.header-dp {
    width: 15.4%;
    margin-left: 0px;
}

.language-session-dropdown img {
    margin-right: 15px;
}

.language-session-dropdown {
    width: 15%;
}

    .language-session-dropdown button {
        width: 100%;
        padding: 12px 0px 12px 15px;
        text-align: left;
        position: relative;
        background: #fff;
        color: #000;
        font-size: 16px;
    }

    .language-session-dropdown .dropdown-toggle::after {
        display: none;
    }

    .language-session-dropdown .dropdown-toggle {
        background: url(../assets/images/signup-dropdown.svg) no-repeat;
        background-position: 95% 20px;
        background-color: #f6f6f6;
        border: 1px solid #fff;
        border-radius: 5px;
    }

    .language-session-dropdown .btn:hover {
        color: #000 !important;
        background-color: #F6F6F6;
        border-color: #F6F6F6;
    }

    .language-session-dropdown .dropdown-toggle:active {
        background: url(../assets/images/signup-dropdown.svg) no-repeat;
        background-position: 95% 20px;
        background-color: #F6F6F6 !important;
    }

    .language-session-dropdown .dropdown-menu {
        background-color: #f6f6f6;
    }

.lessons-input-fielsMain .lesson-forms-selectors .form-select {
    font-size: 16px;
    font-weight: 500;
    --bs-form-select-bg-img: none;
    border-radius: 5px;
    padding: 6px 98px 5px 15px;
    background: #F6F6F6;
    height: 40px;
    color: #40474D;
}

.lesson-forms-selectors .form-select {
    background: url(../assets/images/signup-dropdown.svg) no-repeat !important;
    background-position: 95% 17px !important;
    background-color: #f6f6f6 !important;
}

.lesson-forms {
    width: 26%;
}

    .lesson-forms .form-control {
        padding: 13px 15px 12px 15px;
        border-radius: 5px;
        font-size: 16px;
        color: #000;
        background: #F6F6F6;
        height: 40px;
    }

.lessons-input-fielsMain .lesson-forms .form-control {
}

.sentence .form-control {
    padding: 13px 15px 12px 13px;
}
/*.lesson-forms .form-control::placeholder {
            font-size: 16px;
            color: #40474D;
        }*/
.language-session-dropdown .dropdown-menu {
    width: 100%;
}

.lesson-title-main {
    display: flex;
    margin-top: 20px;
    margin-bottom: 80px;
}

.lesson-title-left {
    width: 50%;
    background: #F6F6F6;
    margin-right: 15px;
    border-radius: 10px;
    /* padding: 20px;*/
    padding: 20px 20px 30px 20px;
    position: relative;
}

.lesson-title-right {
    width: 50%;
    background: #F6F6F6;
    margin-left: 15px;
    border-radius: 10px;
    padding: 20px;
    position: relative;
}

.lesson-left-flag h2 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0px;
    color: #000;
    /* width: 152px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
}

.lesson-left-flag span {
    width: 13px;
    height: 13px;
    background: #3D6927;
    display: inline-block;
    border-radius: 50%;
}

.word-link-button {
    background: #FF0000;
    display: inline-block;
    padding: 3px 23px;
    border-radius: 17.5px;
    text-decoration: none;
}

    .word-link-button a {
        text-decoration: none;
        color: #40474D;
        font-weight: 700;
        font-size: 14px;
    }

.unpublished {
    background: #FCBB30;
}

.lesson-link-button {
    background: #FCBB30;
    display: inline-block;
    padding: 3px 23px;
    border-radius: 17.5px;
}

    .lesson-link-button a {
        text-decoration: none;
        color: #40474D;
        font-weight: 700;
        font-size: 14px;
    }

.lesson-flag-main {
    display: flex;
    justify-content: space-between;
}

.lesson-left-flag {
    display: flex;
    align-items: center;
}

    .lesson-left-flag img {
        margin: 0px 15px 0px 15px;
        border-radius: 50%;
    }

.lesson-description span {
    font-weight: 600;
    font-size: 14px;
}

.lesson-description label {
    font-size: 16px;
    font-weight: 700;
    color: #000;
}

.lesson-description {
    margin-top: 10px;
    margin-bottom: 20px;
    /*padding-bottom: 20px;*/
    /*border-bottom: 1px solid rgb(0 0 0 / 30%);*/
    line-height: 1.1;
}

.lesson-word-dash-main {
    /*    margin-bottom: 80px;*/
}

.blue span {
    background: #3072F4;
    margin: 0px 2px 0px 3px;
}

.musterd span {
    background: #B6B02B;
    margin: 0px 2px 0px 3px;
}

.brown span {
    background: #CB6E1E;
    margin: 0px 2px 0px 3px;
}

.redd span {
    background: #DD0010;
    margin: 0px 2px 0px 3px;
}

.yelloww {
    background: #F6EF29;
    padding: 3px 25px;
}

.greenn {
    background: #62B142;
    padding: 3px 34px;
}

.greenn-color {
    color: #107A3C;
}

.pagination-lesson-main .pagination-box {
    margin-left: 10px;
}

.pagination-lesson-main .dropdown-body .dropdown-toggle {
    margin: 0px 20px 0px 5px;
}

.pagination-lesson-main .dropdown-body span {
    color: #40474D;
}

.pagination-lesson-main .pagination-box .pagination li span {
    color: #40474D;
}

.pagination-lesson-main {
    margin-bottom: 0px;
    margin-top: 43px;
}

.add-suggested-popup-pagination {
    margin-top: 16px;
}

.pagination-lesson-main .form-main .btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
    color: #fff !important;
    border: 1px solid #dee2e6;
    background-color: #61B0DC;
}

.pagination-main-two .dropdown-body .dropdown-toggle::after {
    margin-left: 10px;
}
/*Lesson Right Starts*/
.lesson-languages-main {
    display: flex;
    justify-content: space-between;
    /*margin-bottom: 20px;*/
}

.lesson-left {
    width: 65%;
    margin-right: 10px;
}

.lesson-languages-main .lesson-left .form-control {
    height: 41px;
    min-height: unset;
    border: 1px solid #cdcdcd;
}

.lesson-languages-main .lesson-left label {
    top: -7px;
}

.languages-right {
    width: 35%;
    margin-left: 10px;
}

.lesson-languages-main .lesson-left .lesson-forms {
    width: 100%;
}

.lesson-languages-main .languages-right .language-session-dropdown {
    width: 100%;
}

.lesson-languages-main .lesson-left .lesson-forms .form-control {
    background: #fff;
    border: 1px solid #CDCDCD;
}

.lesson-languages-main .languages-right .language-session-dropdown .dropdown-toggle {
    background-color: #fff !important;
    border: 1px solid #CDCDCD;
}

.lesson-languages-main .languages-right .language-session-dropdown .dropdown-menu {
    background-color: #fff;
}

.lesson-left .lesson-forms .form-control:focus {
    box-shadow: none;
}

.lesson-description-main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

    .lesson-description-main .css-1a5f8b4-control {
        border-radius: 5px;
    }

.description-left {
    margin-right: 10px;
    width: 65%;
}

.focus-right {
    width: 35%;
}

.focus-right {
    margin-left: 10px;
    position: relative;
}

.description-block {
    padding: 15px 15px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #CDCDCD;
    min-height: 255px;
    position: relative;
}

    .description-block::before {
        content: "";
        width: 21px;
        height: 100%;
        background: #ffffff;
        display: inline-block;
        position: absolute;
        right: 15px;
        top: 0px;
    }

.dashbord-textarea {
    height: 161px;
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    font-weight: 100;
    max-width: 335px;
}

.focus-block textarea {
    width: 100%;
    height: 111px;
    border: none;
    outline: none;
    resize: none;
    font-weight: 100;
    max-width: 160px;
}

.dashbord-textarea:focus-visible {
    box-shadow: none;
}

.description-block label {
    font-size: 16px;
    color: #000;
    display: block;
    /* margin-bottom: 20px; */
    font-weight: 700;
}

.description-block span {
    font-size: 14px;
    color: #000;
    max-width: 366px;
    display: block;
}

.focus-block {
    padding: 15px 15px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #CDCDCD;
    min-height: 185px;
    margin-bottom: 12px;
    position: relative;
}

    .focus-block::before {
        content: "";
        width: 26px;
        height: 80%;
        background: #ffffff;
        display: inline-block;
        position: absolute;
        right: 15px;
        top: 0px;
    }

    .focus-block label {
        font-size: 16px;
        color: #000;
        display: block;
        /* margin-bottom: 10px; */
        font-weight: 700;
    }

    .focus-block span {
        font-size: 14px;
        color: #000;
        display: block;
    }

.difficulty-dropdown .language-session-dropdown {
    width: 100%;
}

.difficulty-dropdown .css-j6oinj-control {
    border-radius: 5px;
    height: 40px;
    background: #f6f6f6;
}

.difficulty-dropdown .css-qav5hl-singleValue img {
    width: 30px !important;
}

.paragraph {
    position: relative;
}
    /*.paragraph::before {
        content: "";
        width: 17px;
        height: 100%;
        background: #ffffff;
        display: inline-block;
        position: absolute;
        right: 0px;
        top: 0px;
    }*/
    .paragraph ::-webkit-scrollbar {
        width: 10px;
    }

    .paragraph ::-webkit-scrollbar-thumb {
        height: 50px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    .paragraph ::-webkit-scrollbar-thumb {
        background-color: #a7a7a7;
    }

    .paragraph textarea {
        width: 100%;
        height: 230px;
        border: none;
        outline: none;
        background: #fff;
        resize: none;
        font-weight: 100;
        /* max-width: 563px;*/
    }

.difficulty-dropdown .css-1u9des2-indicatorSeparator {
    display: none;
}

.difficulty-dropdown .css-qav5hl-singleValue img {
    width: auto !important;
}

.difficulty-dropdown .css-j6oinj-control {
    background: #fff;
}

.difficulty-dropdown {
    margin-top: 0px;
}

.difficulty-dropdown {
    position: relative;
}

    .difficulty-dropdown .example-select-box, .difficulty-dropdown .header-flags-select-box {
        display: flex;
        align-items: center;
        padding: 5px 10px 5px 10px;
        width: 100%;
        height: 40px;
        background: url(../assets/images/signup-dropdown.svg) no-repeat;
        background-position: 95% 16px;
        border-radius: 5px;
        background-color: #f6f6f6;
        border: 1px solid #CDCDCD !important;
    }

        .difficulty-dropdown .example-a li span, .difficulty-dropdown .example-select-box li span, .difficulty-dropdown .header-flags-select-box, .difficulty-dropdown .header-flags-select-box li span {
            margin-left: 8px;
            font-weight: 400 !important;
        }

    .difficulty-dropdown .example-a li {
        display: flex;
        align-items: center;
        border-bottom: 0px !important;
        padding: 5px 20px 5px 20px;
    }

        .difficulty-dropdown .example-a li:first-child {
            padding-top: 20px;
        }

        .difficulty-dropdown .example-a li:last-child {
            padding-bottom: 20px;
        }

    .difficulty-dropdown .example-select-box:hover, .difficulty-dropdown .header-flags-select-box:hover {
        box-shadow: none;
    }

    .difficulty-dropdown .example-a img, .example-select-box img, .difficulty-dropdown .header-flags-a img, .header-flags-select-box img {
        width: 30px !important;
        height: 30px;
        margin-left: 0px;
        border-radius: 50px;
        object-fit: cover;
    }

    .difficulty-dropdown .example-select-box {
        background-color: #fff;
    }

        .difficulty-dropdown .example-select-box:hover {
            background-color: #fff;
        }

.span-dots-main {
    margin-top: 20px;
}

    .span-dots-main .dropdown-image {
        position: absolute;
        top: 22px;
        right: 10px;
    }

.pagination-box-main .pagination-lesson-main .dropdown-body .dropdown-toggle {
    /* background: #ffffff; */
    color: #40474D;
}

.lesson-paragraph-main {
    border-radius: 5px;
    background-color: #fff;
    padding: 15px;
    margin-top: 20px;
    border: 1px solid #cdcdcd;
    width: 584px;
}

.lesson-paraBox {
    display: flex;
    justify-content: space-between;
}

    .lesson-paraBox h2 {
        font-weight: 400;
        font-size: 16px;
        color: #000;
        font-weight: 700;
    }

.lesson-para-right h2 {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    text-align: right;
}

.paragraph p {
    font-size: 14px;
    color: #000;
    margin-top: 20px;
}

.words-add {
    text-align: right;
}

    .words-add a {
        text-decoration: none;
        color: #000;
        font-size: 14px;
    }

.suggested-lessons {
    /*margin-top: 22px;*/
    margin-top: 25px;
    margin-bottom: 25px;
}

    .suggested-lessons .my-lessons h2 {
        font-size: 28px;
        font-weight: 700;
        color: #000;
        margin-bottom: 0;
        margin-top: 5px;
        margin-right: 5px;
    }

.span-dots-main .span-dots-select-box li {
    flex-direction: row-reverse;
    justify-content: flex-end;
}

    .span-dots-main .span-dots-select-box li span {
        margin-left: 10px !important;
        margin-right: 15px;
    }

.span-dots-main .span-dots-select-box {
    /* height: 34px; */
    background-color: #fff;
    padding: 14px 0px;
    border: 1px solid #CDCDCD;
    border-radius: 5px;
}

    .span-dots-main .span-dots-select-box:hover {
        /* height: 34px; */
        background-color: #fff;
        border: none;
        height: 50px;
    }

    .span-dots-main .span-dots-select-box img {
        width: auto;
        height: auto;
    }

.span-dots-main .span-dots-a li {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

    .span-dots-main .span-dots-a li span {
        margin-left: 10px;
    }

    .span-dots-main .span-dots-a li img {
        margin: 0px 0px 0px 10px;
        width: auto;
        height: auto;
    }

.suggested-lessons .my-lessons-dropdown .dropdown-toggle {
    width: 40px;
    height: 40px;
}

.lesson-title-rightBox {
    margin-top: 20px;
}

.lesson-del-button {
    width: 40px;
    height: 40px;
    background: #EF4536;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.del-publish-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* width: calc(100% - 40px); */
    /* position: absolute; */
    /* margin-top: 140px; */
    /*position: absolute;
    bottom: 0px;
    left: 0px;*/
    width: calc(100% - 2px);
    background: #f6f6f6;
    padding: 0px 0px 10px 0px;
}

.word-dashbord-right .del-publish-buttons {
    bottom: 8px;
    padding: 7px 10px 9px 10px;
}

.word-dashbord-right .word-delPublish-btns {
    position: unset !important;
    bottom: 9px !important;
    left: 0;
}

.difficulty-dp {
    width: 34.3%;
    margin-left: 10px;
    position: relative;
}

.del-btn a {
    background: #EF4536;
    padding: 10px 42px;
    border-radius: 5px;
    display: inline-block;
    font-weight: 700;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
}

.publish-unbublish-btns .un-publish {
    font-weight: 700;
    background: #A7A7A7;
    display: inline-block;
    padding: 10px 21px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
}

.publish-unbublish-btns .publish {
    font-weight: 700;
    background: #61B0DC;
    display: inline-block;
    padding: 10px 37px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    margin-left: 15px;
}
/*Add Suggested Lessons PopUP*/
.add-suggested-lessons .terminology {
    width: 100%;
}

.add-suggested-lessons .terminology {
    align-items: center;
    margin-top: -25px;
    justify-content: center;
}

.add-suggested-lessonsMain {
    max-width: 600px;
    margin: 0 auto;
    /* position: absolute;
    bottom: 13px;
    right: 20px;*/
}

.add-suggested-lessons .lesson-forms {
    width: 100%;
    margin-top: 10px;
}

    .add-suggested-lessons .lesson-forms .form-control {
        background: #fff;
        border-color: #cdcdcd;
    }

.lesson-forms .form-control:focus {
    box-shadow: none !important;
    border-color: #cdcdcd;
}

.sentence .form-floating > .form-control-plaintext ~ label, .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
    transform: scale(.85) translateY(-16px) translateX(0.15rem);
    font-size: 12px;
    color: #40474D;
    font-weight: 500;
    background: rgb(246,246,246);
    background: linear-gradient(180deg, rgb(246 246 246) 0%, rgb(255 255 255) 100%);
}

.search-bar-main .sentence .form-floating > .form-control-plaintext ~ label, .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
    transform: scale(.85) translateY(-5px) translateX(0.15rem) !important;
    font-size: 12px;
    color: #40474D;
    font-weight: 500;
    background: none !important;
}

.add-suggested-lessonsMain .lesson-forms {
    position: relative;
}

    .add-suggested-lessonsMain .lesson-forms img {
        position: absolute;
        top: 10px;
        right: 12px;
    }

.add-suggested-lessonsMain .lesson-description {
    text-align: left;
}

.add-suggested-popUp-main {
    margin-top: 25px;
}

.add-suggested-Lessons-Pagination {
    margin-bottom: 25px;
}

.add-suggested-lessonsMain .dropdown-body span {
    color: #40474D;
    font-size: 12px;
}

.add-suggested-lessonsMain .pagination-box .pagination li span {
    color: #40474D;
    font-size: 12px;
}

.add-suggested-lessonsMain .pagination-box {
    margin-left: 10px;
}

.add-suggested-lessonsMain .dropdown-body .dropdown-toggle {
    margin: 0px 7px 0px 7px;
}

.add-suggested-lessonsMain .dropdown-body .dropdown-toggle {
    /*background: #f6f6f6 !important;*/
    border: 1px solid #D0D0D0 !important;
}

.add-suggested-lessonsMain .pagination-box .page-link {
    background: #f6f6f6;
    border: 1px solid #D0D0D0;
}

.add-suggested-lessonsMain .pagination-box .page-link {
    padding: 1px 9px;
    font-size: 12px;
    color: #40474D;
}

.add-suggested-lessonsMain .dropdown-body .dropdown-toggle {
    padding: 1px 0px 1px 7px;
    font-size: 12px;
    color: #40474D;
}

.add-suggested-lessonsMain .pagination-main {
    /* margin-bottom: 25px; */
}

.add-suggested-lessonsMain .pagination-box .pagination li span {
    margin: 0px 9px;
}

.add-suggested-lessonsMain .add-button-suggested-Lessons a {
    font-size: 16px;
    background: #61B0DC;
}

.add-button-suggested-Lessons a {
    font-size: 20px;
    color: #fff;
    text-transform: capitalize;
    background: #FBAB40;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    text-decoration: none;
}

.blury-heading {
    margin: 8px 0px 20px 11px !important;
    opacity: .6;
}

    .blury-heading img {
        border-radius: 50%;
        margin-right: 5px;
    }

.leson-dashbord .dropdown-toggle {
    width: 40px;
    height: 40px;
}

    .leson-dashbord .dropdown-toggle:active {
        background: #f66234;
        border: 1px solid #f66234;
    }

.suggested-lessons .my-lessons-dropdown .dropdown-toggle:active {
    background: #fbab40;
    border: 1px solid #fbab40;
}

.suggested-lesson-checkBoxes .form-check .form-check-input {
    width: 25px;
    height: 25px;
    border-radius: 3px;
}

    .suggested-lesson-checkBoxes .form-check .form-check-input:checked {
        background-color: #61B0DC;
        border-color: #61B0DC;
    }

    .suggested-lesson-checkBoxes .form-check .form-check-input:focus {
        border-color: none;
        box-shadow: none;
    }

    .suggested-lesson-checkBoxes .form-check .form-check-input:checked[type=checkbox] {
        --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
    }

.add-suggested-Lessons-Pagination .dropdown-body .dropdown-toggle::after {
    margin-left: 2px;
}

.add-suggested-Lessons-Pagination .pagination-main {
    margin-bottom: 30px;
}

.admin-dashbord-navMain {
    width: 100%;
}

    .admin-dashbord-navMain li:nth-child(1) {
        margin-right: 20px;
    }

    .admin-dashbord-navMain li:nth-child(2) {
        margin-right: 20px;
    }

    .admin-dashbord-navMain li:nth-child(5) {
        margin-right: auto;
    }

.admin-dashbord-logo {
    /* border-right: 1px solid rgb(255 255 255 / 25%); */
    padding-right: 13px;
}

.dashbordsLeft-border {
    border-left: 1px solid rgb(255 255 255 / 25%);
    padding-left: 24px;
}

.admin-dashbord-image {
    width: 40px;
    height: 40px;
    background-color: #61B0DC;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
}

.admin-dashbord-navbar-main span {
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    display: inline-block;
    margin-top: 5px;
}

.admin-dashbord-navbar-main a {
    margin-right: 0 !important;
}

.lesson-desc {
    padding-bottom: 65px;
}
/*lesson four popUp*/
.popUp-MessageBox {
    position: fixed;
    top: 150px;
    right: 15px;
    border: 3px solid #3CA34C;
    border-radius: 13px;
}

.popup-messagee-main {
    background: #F6F6F6;
    padding: 20px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

    .popup-messagee-main span {
        margin-right: 30px;
        font-size: 16px;
        color: #000;
        max-width: 265px;
        display: inline-block;
    }

    .popup-messagee-main .tickk {
        margin-right: 19px;
    }

.toast {
    background: #F6F6F6;
}

.toast-message {
    color: #000;
    font-size: 16px;
}

.toast-progress {
    background-color: #000;
}

.toast-success:before {
    color: green;
}

.toast-error:before {
    color: red;
}
/*Word Dashbord starts here*/
.word-dashbord-form .lesson-forms {
    width: 25% !important;
}

.word-dashbord-form .lessons-input-fielsMain {
    display: flex;
    align-items: center;
}

.wordDashbord-forms {
    width: 88%;
}

.missing-language {
    background: #FC6D41;
}

.word-dashbord-form .my-lessons-dropdown .dropdown-menu {
    padding: 15px 15px 15px 15px;
    width: 396px;
}

.word-dashbord-form .word-dashbord-dropdown .suggested-lesson-checkBoxes {
    display: flex;
    flex-wrap: wrap;
}

.word-dashbord-dropdown .form-check label {
    margin: 5px 0px 0px 12px;
}

.word-dashbord-form .word-dashbord-dropdown .form-check {
    margin-bottom: 20px;
    width: 50%;
}

.word-dashbord-form .wordDashbord-dp .btn.show, .btn:first-child:active {
    /*    border: 1px solid #fbab40 !important;
    background-color: #fbab40 !important;*/
}

.amazing-box {
    background: #D4D4D4;
    position: absolute;
    padding: 25px 25px 0px 25px;
    left: 0;
    top: 0;
    right: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

    .amazing-box .lesson-description {
        border-bottom: none;
        padding-bottom: 0px;
    }

.word-dashbord-left {
    position: relative;
}

.amazingly-main {
    display: flex;
}
/*.amazingly-main .lesson-forms .form-control {
        padding: 13px 15px 12px 15px;
    }*/
.word-dashbord-right .lesson-forms {
    width: 65% !important;
}

.word-dashbord-right .header-dp {
    width: 32%;
}

.word-dashbord-right .lesson-forms .form-control {
    background: #fff;
}

.word-dashbord-right .lesson-forms {
    margin-right: 20px;
}

.word-dashbord-right .difficulty-dropdown .header-flags-select-box {
    background-color: #fff;
    margin-left: 0px;
}

.wordDashbord-para p:nth-child(1) span {
    font-size: 16px;
    color: #000;
    font-weight: 700;
}

.wordDashbord-para p:nth-child(2) span {
    font-size: 16px;
    color: #000;
    font-weight: 700;
}

.wordDashbord-para p {
    font-size: 14px;
    color: #40474D;
    font-weight: 600;
    margin-bottom: 5px;
}

.wordDashbord-Languages-dp {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wordDashbord-images img {
    border-radius: 50%;
    object-fit: cover;
    width: 40px;
    height: 40px;
}
/* .wordDashbord-LanguagesForms .lesson-forms {
    width: 100% !important;
} */
.wordDashbord-LanguagesForms {
    margin-top: 20px;
    margin-bottom: 50px;
}

    .wordDashbord-LanguagesForms .lesson-forms label {
        margin-bottom: 20px;
        margin-top: 5px;
    }
    /* .wordDashbord-LanguagesForms .lesson-forms .form-control::placeholder {
        color: #40474D;
    }*/
    .wordDashbord-LanguagesForms .lesson-forms .amaz-heading {
        font-size: 18px;
        color: #000;
        font-weight: 600;
    }

.difficulty-dropdown .header-flags-a {
    background: #ffffff;
    padding: 0px 10px 0px 10px;
    border: 1px solid #CDCDCD;
    border-radius: 5px;
}

    .difficulty-dropdown .header-flags-a li {
        border-bottom: none !important;
        padding: 5px 5px 0px 10px;
    }

        .difficulty-dropdown .header-flags-a li span, .header-flags-select-box li span {
            margin-left: 10px;
            font-size: 14px;
            color: #40474D;
        }

        .difficulty-dropdown .header-flags-a li:first-child {
            border-bottom: none !important;
            padding-top: 20px;
        }

        .difficulty-dropdown .header-flags-a li:last-child {
            border-bottom: none !important;
            padding-bottom: 20px;
        }

.difficulty-dropdown .header-flags-b {
    border-radius: 5px;
}

.blur-image {
    opacity: 0.3;
}

.wordDashbord-LanguagesForms .lesson-forms {
    width: 100% !important;
    display: flex;
}

.wordDashbord-title {
    height: 110px;
    border-bottom: 1px solid rgb(0 0 0 / 30%);
    margin-bottom: 20px;
}

.lesson-title-box:hover {
    background: #D4D4D4;
}

.lesson-title-box {
    padding: 20px 5px 0px 5px;
    border-bottom: 1px solid rgb(0 0 0 / 30%);
}

.language-PopUp .modal-dialog {
    max-width: 500px;
}

.language-PopUp .modal-body {
    padding: 40px;
}

.language-PopUp .terminology {
    margin-top: -70px;
}

.drop-flag-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    padding: 10px;
    border: 1px dashed #CDCDCD;
    border-radius: 5px;
}

    .drop-flag-main img {
        margin-bottom: 10px;
    }

    .drop-flag-main span a {
        color: #61B0DC;
        font-size: 14px;
        font-weight: 600;
    }

.add-language {
    background: #61B0DC;
    border-radius: 5px;
    padding: 1px 0px;
    margin-top: 20px;
}

    .add-language button {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        text-decoration: none;
    }

    .add-language .btn:hover {
        color: #fff;
    }

.del-language {
    text-align: left;
}

    .del-language a {
        margin: 20px 0px 15px 0px;
        display: block;
        text-decoration: none;
        color: #40474D;
        font-size: 14px;
    }

.language-selector {
    display: flex;
    align-items: center;
}

.lang-edit-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.lang-edit-del {
    display: flex;
    align-items: center;
}

.editt {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FBAB40;
    margin-right: 5px;
}

.dell {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EF4536;
    margin-left: 5px;
}

.language-selector span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
}

.languages-edit-delBox .lang-edit-main:not(:last-child) {
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(0 0 0 / 30%);
}

.language-selector img {
    border-radius: 50%;
}

.add-suggested-lessonsMain .lesson-forms .form-control::placeholder {
    color: #40474D;
    font-size: 14px;
}

.lessons-input-fielsMain .lesson-forms .form-control::placeholder {
    color: #40474D;
    font-size: 14px;
    font-weight: 400;
}

.css-qav5hl-singleValue {
    font-size: 14px;
    font-weight: 400;
    color: #40474D;
}

.signn-up {
    text-decoration: none;
}

.add-suggested-lessonsMain .pagination-main {
    justify-content: flex-end;
}

.modal-login form .form-control {
    margin-bottom: 20px;
}

.wordDashbord-images {
    display: flex;
}

    .wordDashbord-images div {
        margin: 0px 2px;
        /* position: relative;*/
    }
    /*.wordDashbord-images span {
        visibility: hidden;*/
    /* width: 80px; */
    /*background-color: #2F353D;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 12px;
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
        position: absolute;
        top: -38px;
        left: 0px;
        z-index: 1;
    }*/
    /*  .wordDashbord-images span::before {
            content: "";
            background-image: url(../assets/images/signup-dropdown.svg);
            background-size: contain;
            background-repeat: no-repeat;
            width: 13px;
            height: 8px;
            position: absolute;
            bottom: -6px;
            left: 50%;
            transform: translateX(-50%);
        }*/
    .wordDashbord-images .eng-uk-span span {
        top: -38px;
        left: -2px;
    }

    .wordDashbord-images div:hover span {
        visibility: visible;
    }
/*.wordDashbord-images div:hover span {
        visibility: visible;
    }

    .wordDashbord-images div:hover span {
        visibility: visible;
    }

    .wordDashbord-images div:hover span {
        visibility: visible;
    }*/
.contact-us-formss {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

    .contact-us-formss .form-control {
        width: 49%;
        height: 45px;
        margin-bottom: 20px;
    }

.subject-bar {
    width: 100% !important;
}

.suggested-lessonss h2 {
    color: #fff;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 30px;
}

.suggested-lessonss {
    margin-bottom: 86px;
}

.production-popup-main .terminology h2 {
    font-size: 28px;
}

.lesson-three-dashbord {
    display: unset;
}

.lesson-three-left {
    margin-top: 10px;
}

.eng-main {
    text-align: left;
}

.eng-word-block {
    display: flex;
    align-items: center;
}

    .eng-word-block h2 {
        font-size: 16px;
        margin-bottom: 0px;
        margin-right: 10px;
    }

.eng-proncia {
    display: flex;
}

    .eng-proncia h2 {
        font-size: 16px;
        color: #000;
        margin-bottom: 5px;
    }

    .eng-proncia span {
        color: #40474D;
        font-size: 16px;
    }

.lang-main-para {
    font-size: 14px;
}

.native-lang-main {
    text-align: left;
    margin-top: 15px;
}

.stopp-button span {
    margin-bottom: 0 !important;
}

.css-1u9des2-indicatorSeparator {
    display: none;
}

.difficulty-dropdown .css-1a5f8b4-control {
    margin: 0px 0px 0px 10px !important;
    background: #f6f6f6;
    border-radius: 5px;
}

.difficulty-dp .rs-dropdownImg {
    position: absolute;
    top: 17px;
    right: 13px !important;
}

.focus-right .rs-dropdownImg {
    position: absolute;
    top: 231px;
    right: 12px;
}

.pagination-main .dropdown-body {
    margin-top: 10px;
}

.pagination-box .dropdown-items .dropdown-toggle {
    /*background: #F6F6F6;*/
    /*  background: none;*/
    font-size: 12px;
    color: #40474D;
    border: 1px solid #D0D0D0 !important;
    /*  padding: 0px 4px;*/
    padding: 1px 5px;
    border-radius: 2px;
}

.pagination-lesson-main .pagination-box {
    margin-top: 10px;
}

.l-arrow {
    margin-right: 2px;
}

.R-arrow {
    margin-right: 2px;
}

.main-page-pagination .pagination-box {
    margin-top: 10px;
}

    .main-page-pagination .pagination-box .dropdown-items .dropdown-toggle {
        padding: 4px 7px;
    }

.whitebox-production {
    display: flex;
    justify-content: unset !important;
}

    .whitebox-production h2 {
        width: unset !important;
    }

.user-dashbord-navMain li:nth-child(1) {
    margin-right: auto;
}

.user-dashbord-navMain {
    width: 100%;
}

.circular-progress-bar {
    margin-top: 30px;
}
/*React Selector Dropdown Fix*/
.css-1o7wkx6-MenuPortal div div > div:not(:last-child) {
    border-bottom: 1px solid #cdcdcd;
}

.css-1o7wkx6-MenuPortal div div > div:hover {
    background: #f6f6f6;
    border-bottom: 1px solid #cdcdcd;
}

.css-1o7wkx6-MenuPortal div div > div:active {
    background: #f6f6f6;
}

.desc-saved span {
    font-weight: 600;
    font-size: 12px;
}

.desc-saved {
    display: flex;
    gap: 12px;
    align-items: center;
}

.focus-saved {
    display: flex;
    align-items: center;
    gap: 13px;
    margin-bottom: 10px;
}

    .focus-saved span {
        font-weight: 600;
        font-size: 12px;
    }
/*Media Queries*/
@media screen and (max-width:1560px) {
    .login-world-map .col-md-6 h2 {
        font-size: 42px;
    }

    .login-world-map .col-md-6 p {
        font-size: 17px;
    }

    .world-img img {
        width: 100% !important;
        height: 100%;
        object-fit: cover;
    }
    /*2/29/2024 media querry changes*/
    .sticky-right::before {
        display: none !important;
    }
}

@media screen and (max-width:1366px) {
    .login-world-map .col-md-6 h2 {
        font-size: 35px;
    }

    .login-world-map .col-md-6 p {
        font-size: 16px;
    }

    .dashbord-main .white_header_bg {
        padding-left: 10px;
        padding-right: 10px;
    }

    .difficulty-dropdown .header-flags-select-box {
        margin-left: 2px;
    }
}

@media screen and (max-width:1280px) {
    .login-world-map .col-md-6 h2 {
        font-size: 36px;
    }

    .world-img img {
        max-width: 560px;
    }

    .inner-container {
        padding: 0px 10px;
    }

    .whiteBox-buttons a:nth-child(1) {
        font-size: 18px;
    }

    .whiteBox-buttons a:nth-child(2) {
        font-size: 18px;
    }

    .dashbord-main .white_header_bg {
        padding-left: 10px;
        padding-right: 10px;
    }

    .terminology h2 {
        font-size: 33px;
    }

    .binguo-main {
        margin-top: 0px;
    }

    .pagination-lesson-main {
        justify-content: center;
    }

        .pagination-lesson-main .pagination-box {
            margin-left: 0px;
            margin-top: 10px;
        }

    .lesson-fail-rate-main {
        padding: 25px 0px 25px 0px !important;
    }
}

@media screen and (max-width:1024px) {
    .world-img img {
        width: 100% !important;
        height: 100%;
        object-fit: cover;
    }

    .login-world-map .col-md-6 h2 {
        font-size: 29px;
    }

    .contact-us-main {
        margin-top: 80px;
    }

    .table-inside tr td {
        overflow-y: hidden;
        width: 1024px;
    }

    .table-inside {
        width: 1024px;
    }

    .table-responsiveness {
        overflow-x: scroll;
    }

    .whiteBox-buttons a:nth-child(2) {
        margin-left: 0px;
        margin-top: 10px;
        width: 100%;
        display: block;
    }

    .whiteBox-buttons a:nth-child(1) {
        width: 100%;
        display: block;
    }

    .lesson-starts .terminology {
        width: 60%;
    }

    .levelUp-text {
        font-size: 14px;
    }

    .del-btn a {
        font-size: 15px;
    }

    .publish-unbublish-btns .un-publish {
        font-size: 15px;
    }

    .publish-unbublish-btns .publish {
        font-size: 15px;
        margin-left: 5px;
    }

    .table-inside tbody tr:first-child td {
        padding-top: 25px;
        width: 9%;
    }

    .whiteBox-buttons {
        flex-wrap: wrap;
    }

        .whiteBox-buttons .lesson-start {
            margin: 0 auto;
        }

    .instructions-main {
        width: 100%;
    }

    .terminology-right {
        margin-left: 17px;
    }

    .instructions-main {
        width: 100% !important;
    }

    .difficulty-dropdown .header-flags-a li span, .header-flags-select-box li span {
        margin-left: 3px;
    }

    .del-publish-buttons {
        /*bottom: 0px;*/
        position: unset !important;
    }
    /* newly added media querries */
    .full-screen-blur-main img {
        top: 0vh !important;
        left: 34vh !important;
    }

    .new-lesson-start .terminology-right {
        margin-left: 0px;
    }

    .table-fail-rate-bg {
        width: 1100px;
    }

    .lang-training-main-two .table-inside tr td {
        overflow-y: unset;
    }

    .red-exclamation {
        padding: 0px 10px;
    }

    .green-tick {
        padding: 0px 9px;
    }
}

@media screen and (max-width:991px) {
    .login-world-map .row {
        flex-direction: column-reverse;
    }

    .login-world-map .col-md-6 p {
        max-width: max-content;
    }

    .login-world-map .col-md-6 {
        width: 100%;
        text-align: center;
    }

    .login-world-map .login-world-globe {
        margin-bottom: 30px;
    }

    .levelUp-text {
        font-size: 15px;
    }

    .binguo-header-main ul {
        display: flex;
        align-items: center;
        background: #fff;
        border-radius: 5px;
        width: 250px;
    }

    .binguo-main .collapse ul li a {
        color: #000;
    }

    .binguo-header-main ul li {
        margin: 2px 0px 2px 0px;
    }

    .login-dropdown span {
        color: #000;
    }

    .login-menu img {
        display: none;
    }

    .binguo-header-main ul li button {
        background: none;
        box-shadow: none;
        padding: 0;
    }

    .lessons-input-fielsMain form {
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    .lesson-forms {
        width: 37.6%;
    }

    .lessons-input-fielsMain form div:nth-child(4) {
        margin: 0px 20px 0px 0px;
        width: 48.5%;
    }

    .language-session-dropdown {
        width: 48.5%;
    }

    .lesson-dashbord-right {
        display: unset !important;
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
        /*padding: 20px 20px 80px 20px;*/
        padding: 20px 20px 20px 20px;
    }

    .lesson-dashbord-left {
        width: 100%;
        margin-right: 0;
    }

    .lesson-dashbord-main {
        flex-wrap: wrap;
    }

    .header-dp {
        width: 49.3%;
    }

    .difficulty-dropdown .header-flags-select-box {
        margin-left: 8px;
    }

    .lessons-input-fielsMain form div {
        margin-bottom: 0px !important
    }

    .lessons-input-fielsMain .lesson-forms {
        margin-bottom: 10px !important;
    }

    .lesson-title-main {
        margin-top: 5px;
    }
    /*Newly Added to lesson dashbord*/
    .lessons-input-fielsMain form > div:nth-child(2) {
        width: 20.4%;
    }

    .lessons-input-fielsMain form > div:nth-child(3) {
        margin: 0px 0px 0px 10px;
    }

    .add-suggested-lessonsMain .pagination-main {
        justify-content: center;
    }

    .add-suggested-lessonsMain {
        bottom: 8px;
    }

    .lesson-selector-two .rs-dropdownImg {
        top: 27px;
        right: 15px;
    }

    .sticky-right {
        overflow-y: unset;
        position: unset;
        top: unset;
    }

    .sticky-scroll {
        height: unset !important;
        overflow-y: unset !important;
    }
    .suggested-main-body {
        flex-wrap: wrap;
    }
    .add-suggested-popup-changes .suggested-main-left {
        width: 100% !important;
        padding-right: unset !important;
    }

    .add-suggested-popup-changes .suggested-main-right {
        width: 100% !important;
        border-left: unset !important;
        padding-left: unset !important;
    }
}

@media screen and (max-width:820px) {
    .work-terminlogy .modal-dialog {
        margin: 35px 10px 0px 10px;
    }

    .terminology {
        width: 59%;
    }

    .work-terminology-main {
        flex-wrap: wrap;
        margin-top: 22px;
    }

    .terminology-left p {
        max-width: fit-content;
        margin-bottom: 20px;
    }

    .terminology-left a {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        max-width: unset;
    }

    .terminology-left {
        width: 100%;
    }

    .terminology-right {
        width: 100%;
    }

    .lesson-starts .work-terminology-main {
        flex-wrap: wrap;
    }

    .lesson-starts .terminology-left {
        width: 100%;
        margin-bottom: 30px;
    }

    .lesson-starts .terminology-right {
        width: 100%;
    }

    .levelUp-text {
        font-size: 14px;
    }

    .read-para-main {
        flex-wrap: wrap;
    }

    .read-para-left {
        width: 100%;
        margin-right: 0;
    }

    .read-para-right {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
    }

    .a-plus-main {
        flex-wrap: wrap;
    }

    .a-plus-left {
        width: 100%;
    }

    .lesson-starts .terminology {
        justify-content: flex-start;
    }

    .lesson-starts .whiteBox-heading .numbers {
        margin-left: 15px;
    }

    .retest {
        max-width: none;
    }

    .lesson-forms {
        width: 37.2%;
    }

    .lesson-left-flag img {
        margin: 0px 5px 0px 10px;
    }

    .lesson-link-button a {
        font-size: 12px;
    }

    .yelloww {
        padding: 3px 17px;
    }

    .green {
        padding: 3px 11px;
    }

    .lesson-description label {
        font-size: 15px;
    }

    .pagination-lesson-main {
        margin-top: 25px;
    }

    .lesson-title-main {
        flex-wrap: wrap;
    }

    .lesson-title-left {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .lesson-title-right {
        display: none;
    }

    .css-1a5f8b4-control {
        background: #fff;
        border-radius: 5px;
        height: 40px !important;
    }

    .word-dashbord-right .header-dp {
        width: 50%;
    }

    .header-dp {
        width: 48.9%;
    }

    .word-dashbord-form .lesson-forms {
        width: 47% !important;
    }

    .word-dashbord-right {
        display: block !important;
        width: 100%;
        margin: 0;
        padding-bottom: 120px;
    }

    .wordDashbord-LanguagesForms .lesson-forms {
        width: 50% !important;
    }

    .dropdown-body {
        margin-top: 12px;
        margin-right: 10px;
    }

    .wordDashbord-dp {
        margin-bottom: 20px;
    }

    .whiteBox-Main .row .col-md-4 {
        width: 100%;
    }

    .whiteBox-buttons .lesson-start {
        margin: unset;
    }

    .usa-flagg img {
        margin-right: 0px;
    }

    .instructions-main {
        /* display: flex; */
        /* justify-content: space-between; */
        /* width: 100%; */
        margin-top: 30px;
    }

    .terminology-right {
        margin-left: 0px;
    }
    /* newly added media querries */
    .full-screen-blur-main img {
        top: 0vh !important;
        left: 17vh !important;
    }

    .new-lesson-start .whiteBox-para {
        max-width: fit-content !important;
    }
}

@media screen and (max-width:768px) {
    .binguo-main .navbar-brand img {
        max-width: 190px;
    }

    .contactus-white-bg .name-email {
        width: 100%;
    }

    .contactus-white-bg {
        padding: 50px 10px;
    }

    .pagination-main {
        justify-content: center !important;
    }

    .all-lessons-main .search-bar {
        width: 100%;
        margin-top: 10px;
    }

    .complete-lesson {
        width: 100%;
    }

    .binguo-header-main ul {
        width: 100%;
        margin-top: 10px;
        padding: 10px 0px;
    }

    .word-dashbord-form .lesson-forms {
        width: 100% !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-bottom: 20px !important;
    }

    .word-dashbord-right .header-dp {
        width: 100%;
        margin-bottom: 20px;
    }

    .amazingly-main {
        flex-wrap: wrap;
    }

    .word-dashbord-form .lessons-input-fielsMain {
        flex-wrap: wrap;
    }

    .word-dashbord-form .my-lessons-dropdown {
        margin-left: 0px;
    }

    .wordDashbord-images {
        text-align: right;
    }

    .my-lessons-dropdown {
        /*margin-left: 15px;*/
    }

    .my-dash .leson-dashbord {
        margin-left: 15px;
    }

    .contact-us-formss .form-control {
        width: 100%;
    }

    .lesson-starts .terminology-left {
        /*    margin-bottom: 0px;*/
    }
    /*newly added lesson dashbord classes*/
    .lessons-input-fielsMain form > div:nth-child(2) {
        width: 19.9%;
    }

    .header-dp {
        width: 48.7%;
    }
}

@media screen and (max-width:540px) {
    .lesson-forms {
        width: 100%;
    }

    .lessons-input-fielsMain form div:nth-child(2) {
        margin: 0px 0px 0px 0px;
        width: 100%;
    }

    .lessons-input-fielsMain form div:nth-child(3) {
        margin: 0px 0px 0px 0px;
        height: 41px;
    }

    .lessons-input-fielsMain form div:nth-child(4) {
        margin: 0px 0px 0px 0px;
        width: 100%;
    }

    .language-session-dropdown {
        width: 100%;
    }

    .difficulty-dropdown .header-flags-select-box {
        margin-left: 0px;
    }

    .header-dp {
        width: 100%;
        margin-bottom: 6px;
    }

    .lesson-languages-main {
        flex-wrap: wrap;
    }

    .lesson-left {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .languages-right {
        width: 100%;
        margin-left: 0px;
    }

    .lesson-description-main {
        flex-wrap: wrap;
        margin-top: 16px;
    }

    .description-left {
        margin-right: 0px;
        width: 100%;
    }

    .focus-right {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }

    .word-dashbord-form .lessons-input-fielsMain {
        flex-wrap: wrap;
    }

    .word-dashbord-form .lesson-forms {
        width: 100% !important;
        margin-bottom: 20px !important;
    }

    .word-dashbord-form .my-dash h2 {
        margin-right: 20px;
    }

    .my-dash .leson-dashbord {
        margin-left: 0px;
    }

    .lesson-title-main {
        margin-top: 0px;
    }

    .dashbord-lessons-main {
        margin-bottom: 10px;
    }

    .wordDashbord-images {
        flex-wrap: wrap;
        justify-content: flex-end;
    }

        .wordDashbord-images div {
            margin: 3px;
        }

    .dashbord-searchBar {
        width: 100%;
        margin-top: 20px;
    }
    /* .a-plus-buttons {
        flex-wrap: wrap;
    } */
    .a-plus-right .retest {
        width: 100% !important;
        margin: 0;
    }

    .a-plus-buttons .dashbord-retest {
        margin-top: 15px !important;
    }

    .a-plus-buttons {
        flex-wrap: wrap;
        width: 100%;
    }

    .a-plus-right {
        width: 100%;
    }

    .a-plus-middle {
        margin-top: 20px;
    }
    /*newly added lesson dashbord classes*/
    .lesson-forms-selectors .rs-dropdownImg {
        right: 24px !important;
    }

    .rs-dropdownImg {
        right: 25px !important;
    }

    .difficulty-dropdown .css-1a5f8b4-control {
        margin: 10px 0px 0px 0px !important;
    }

    .difficulty-dropdown .rs-dropdownImg {
        top: 28px;
    }

    .lessons-input-fielsMain form div:nth-child(2) {
        margin: 0px 0px 10px 0px !important;
    }

    .lesson-left-flag h2 {
        font-size: 18px;
    }

    .difficulty-dp {
        width: 100%;
        margin-left: 0px;
    }

    .del-publish-buttons {
        /* width: unset;*/
    }

    .lesson-left-flag {
        flex-wrap: wrap;
    }

    .instructions-main {
        flex-wrap: wrap;
        margin: 0 auto;
        justify-content: center !important;
    }

    .step-three {
        text-align: center;
    }

    .instructions-main .step-one {
        width: 100%;
    }

    .instructions-main .step-two {
        margin: 30px 0px 30px 0px;
    }

    .css-luipbr2-MenuPortalaaa {
        z-index: 9 !important;
    }

    [class*="MenuPortal"] {
        z-index: 99999 !important;
    }

    .css-1mih1ey-MenuPortal {
        z-index: 99999 !important;
    }
}

@media screen and (max-width:420px) {
    .whiteBox-Main .col-md-4 {
        width: 100%;
    }

    .world-img img {
        width: 100% !important;
        height: 100%;
        object-fit: cover;
    }

    .login-world-map .col-md-6 h2 {
        font-size: 25px;
    }

    .pagination-box {
        margin-left: 2px;
        margin-bottom: 30px;
    }

    .dropdown-body {
        margin-left: 11px;
        margin-bottom: 10px;
    }

    .pagination-box {
        margin-left: 0px;
    }

    .work-terminlogy .numbers {
        display: none;
    }

    .terminology {
        width: 100%;
    }

    .terminology-left p {
        margin-top: 0;
    }

    .terminology h2 {
        font-size: 30px;
        margin-top: 20px;
    }

    .lesson-starts .whiteBox-heading .numbers {
        display: none;
    }

    .lesson-starts .terminology {
        width: 100%;
        margin-bottom: 20px;
    }

    .book-img {
        /* display: none; */
        width: 40px;
        height: 40px;
    }

    .production-right .circle-arrows {
        width: 40px;
        height: 40px;
    }

    .lesson-one {
        flex-wrap: wrap;
    }

        .lesson-one a {
            width: 100%;
        }

    .loading-modal .white-loading-box {
        width: 100%;
    }

    .white-box-bg {
        padding: 15px 15px 15px 15px;
    }

    .lesson-start {
        padding: 15px;
    }

    .read-para-left {
        padding: 15px;
    }

    .read-para-right {
        padding: 15px !important;
    }

    .read-para-left h2 {
        font-size: 16px;
    }

    .white-loading-box {
        width: 82.3%;
    }

    .password-reset-box {
        padding: 0px 10px;
    }

    .whiteBox-buttons {
        text-align: center;
    }

    .lesson-title-left {
        padding: 10px;
        margin-top: 7px;
    }

    .my-lessons h2 {
        font-size: 27px;
    }

    .lesson-dashbord-right {
        padding: 20px 20px 20px 20px;
    }

    .del-publish-buttons {
        position: unset;
        flex-wrap: wrap;
    }

    .del-btn {
        width: 100%;
        text-align: center;
    }

    .publish-unbublish-btns {
        width: 100%;
        text-align: center;
    }

    .del-btn a {
        width: 100%;
    }

    .publish-unbublish-btns .un-publish {
        width: 100%;
        margin: 10px 0px 10px 0px;
    }

    .publish-unbublish-btns .publish {
        width: 100%;
        margin-left: 0;
    }

    .paragraph p {
        margin-top: 0px;
    }

    .suggested-lessons .my-lessons h2 {
        font-size: 22px;
    }

    .lesson-left-flag h2 {
        font-size: 16px;
    }

    .add-suggested-Lessons-Pagination .pagination-box .pagination li span {
        margin: 0px 6px;
    }

    .add-suggested-Lessons-Pagination .dropdown-body span {
        font-size: 12px;
        color: #fff;
    }

    .add-suggested-Lessons-Pagination .pagination-box .pagination li span {
        font-size: 12px;
    }

    .add-suggested-Lessons-Pagination .dropdown-body span {
        color: #000;
    }

    .add-suggested-Lessons-Pagination {
        margin-bottom: 20px;
    }

        .add-suggested-Lessons-Pagination .pagination-main {
            margin-bottom: 20px;
        }

    .missing-language {
        padding: 3px 12px;
    }

    .word-dashbord-right {
        padding-bottom: 20px;
    }

    .greenn {
        padding: 3px 19px !important;
    }

    .lesson-link-button {
        padding: 3px 11px;
    }

    .yelloww {
        padding: 3px 25px !important;
    }

    .word-dashbord-form .my-dash h2 {
        margin-right: 10px;
    }

    .pagination-main {
        margin-bottom: 0px;
    }

    .suggested-lessonss h2 {
        font-size: 26px;
    }

    .suggested-lessonss {
        margin-bottom: 10px;
    }
    /*newly lesson dashbord changes*/
    .rs-dropdownImg {
        right: 18px !important;
    }

    .lesson-forms-selectors .rs-dropdownImg {
        right: 17px !important;
    }

    .my-lessons {
        margin-bottom: 20px;
    }
}

@media screen and (max-width:370px) {
    .lesson-left-flag span {
        width: 9px;
        height: 9px;
    }

    .pagination-lesson-main .pagination-box {
        margin-top: 5px;
    }

        .pagination-lesson-main .pagination-box .pagination li span {
            font-size: 12px;
        }

    .pagination-lesson-main .dropdown-body span {
        font-size: 12px;
    }

    .my-lessons h2 {
        font-size: 25px;
    }

    .lesson-left-flag img {
        width: 25px;
    }

    .lesson-del-button {
        width: 35px;
        height: 35px;
    }

    .lesson-start a {
        width: 80px !important;
        height: 80px;
    }

    .terminology h2 {
        font-size: 27px;
    }

    .lesson-strt a {
        width: unset !important;
        height: 40px !important;
    }

    .whiteBox-heading h2 {
        width: unset;
    }
}
/******** Progresss bar **********/
@-webkit-keyframes f {
    0% {
        background-position: 40px 0;
    }

    to {
        background-position: 0 0;
    }
}

@keyframes f {
    0% {
        background-position: 40px 0;
    }

    to {
        background-position: 0 0;
    }
}

.progres {
    overflow: hidden;
    /*margin-top: 1em;*/
    height: 21px;
    border: 4px solid #ccc;
    border-radius: 14px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    background: #A0A0A0;
}

.progres-bar {
    height: 100%;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
}

.progres-bar-striped, .progres-striped .progress-bar {
    background-image: linear-gradient( 135deg, hsl(0deg 0% 100%) 25%, transparent 0, transparent 50%, hsl(0deg 0% 100%) 0%, hsla(0, 0%, 100%) 74%, transparent 0, transparent );
    background-size: 40px 40px;
    box-shadow: inset 0px -8px 7px 0px #00000057;
}

.progres-bar.active, .progres.active .progres-bar {
    -webkit-animation: f 2s linear infinite;
    animation: f 2s linear infinite;
}

.progres-bar-info {
    background-color: #999;
}

.progres-striped .progres-bar-info {
    background-image: linear-gradient( 45deg, hsla(0, 0%, 95%, 0.8) 25%, transparent 0, transparent 50%, hsla(0, 0%, 95%, 0.8) 0, hsla(0, 0%, 95%, 0.8) 75%, transparent 0, transparent );
}
/****************Circular Progress Bar*******************/
.circular-progress-bar .progresss {
    margin: 0 auto;
}

.progresss {
    width: 120px;
    height: 120px;
    font-size: 17px;
    color: #000;
    font-weight: 700;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    background: #fff;
    text-align: center;
    line-height: 117px;
    margin: 20px
}

    .progresss::after {
        content: "";
    }

    .progresss .title {
        position: relative;
        z-index: 100;
    }

    .progresss .overlay {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: #fff;
    }

    .progresss .left, .progresss .right {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 11px solid #0f7a3c;
        border-radius: 100px 0px 0px 100px;
        border-right: 0;
        transform-origin: right;
    }

    .progresss .left {
        animation: load1 1s linear forwards;
    }

    .progresss:nth-of-type(2) .right, .progresss:nth-of-type(3) .right {
        animation: load2 .5s linear forwards 1s;
    }

    .progresss:last-of-type .right, .progresss:first-of-type .right {
        animation: load3 .8s linear forwards 1s;
    }

@keyframes load1 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

@keyframes load2 {
    0% {
        z-index: 100;
        transform: rotate(180deg);
    }

    100% {
        z-index: 100;
        transform: rotate(270deg);
    }
}

@keyframes load3 {
    0% {
        z-index: 100;
        transform: rotate(180deg);
    }

    100% {
        z-index: 100;
        transform: rotate(315deg);
    }
}
/*Floating labels*/
:root {
    --color-black: #1a1a1a;
    --color-grays: #a6a6a6;
    --color-light: #f5f5f5;
    --color-white: #fff;
    --color-blues: #36f;
    --shadow-normal: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

*, *::before, *::after {
    /* padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    list-style-type: none;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility; */
}

main {
    overflow: hidden;
}

.form-control {
    position: relative;
    width: 100%;
    height: 45px;
    /* margin-bottom: 20px; */
}

.form-label {
    position: absolute;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: inherit;
    left: 1rem;
    top: 0.75rem;
    color: var(--color-gray);
    /* background: var(--color-white); */
    transition: all 0.3s ease;
    padding: 0 6px;
}

.form-input {
    position: absolute;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: inherit;
    height: 45px !important;
    top: 0;
    left: 0;
    z-index: 1;
    resize: none;
    width: 100%;
    height: auto;
    padding: 11px 38px 11px 21px;
    border-radius: 0.25rem;
    border: 1px solid #CDCDCD;
    color: var(--color-black);
    background: transparent;
    transition: all 0.2s ease-in-out;
}

    /*.form-input::placeholder {
        opacity: 0;
        visibility: hidden;
        color: transparent;
    }*/

    .form-input:focus {
        outline: none;
        border: 1px solid #CDCDCD;
    }

        .form-input:focus ~ .form-label {
            top: -0.75rem;
            left: 1rem;
            z-index: 5;
            font-size: 0.875rem;
            font-weight: 500;
            color: #40474D !important;
            transition: all 0.2s ease-in-out;
        }

            .form-input:focus ~ .form-label:before {
                content: "";
                background-color: #fff;
                position: absolute;
                left: 0;
                top: 11px;
                width: 100%;
                height: 2px;
                z-index: -1;
            }

    .form-input:not(:placeholder-shown).form-input:not(:focus) ~ .form-label {
        top: -0.75rem;
        left: 1rem;
        z-index: 9;
        font-size: 0.875rem;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
        background: rgb(246,246,246);
        background: linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(255,255,255,1) 100%);
    }

.contact-column {
    max-width: 30rem;
    width: 100%;
    height: auto;
    margin: 0 auto;
}
/*Floating Textarea*/
#cancellationNotesContainer {
    position: relative;
    float: left;
    width: 100%;
}

    #cancellationNotesContainer label {
        position: absolute;
        top: 0;
        left: 22px;
        padding: 0.9rem 0;
        color: rgba(0, 0, 0, 0.87);
        pointer-events: none;
        transition: all 0.15s ease;
        color: #40474D;
    }

    #cancellationNotesContainer textarea {
        background-color: #fff;
        padding: 0.875rem 0;
        border: 2px solid #cdcdcd !important;
        font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1rem;
        border: 0;
        border: 1px solid #737373;
        width: 100%;
        border-radius: 4px;
        min-height: 7rem;
        resize: none;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

        #cancellationNotesContainer textarea:focus, #cancellationNotesContainer textarea.not-empty {
            outline: 0;
            border: 1px solid #f8a94c;
        }

            #cancellationNotesContainer textarea:focus ~ label, #cancellationNotesContainer textarea.not-empty ~ label {
                font-size: 16px;
                color: #212529;
                top: -22px;
                transition: all 0.15s ease;
                background: #f6f6f6;
                padding-left: 5px;
                padding-right: 5px;
                padding-bottom: 0px;
                height: 24px;
            }

.current-characters {
    display: inline;
}
/* Extra padding was effecting login button */
.binguo-main .navbar-nav {
    --bs-nav-link-padding-y: none;
}

.binguo-main .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.cursor {
    cursor: pointer;
}

.add-button-suggested-Lessons {
    margin-top: 30px;
}

.lesson-forms-selectors .css-b62m3t-container .css-13f45xl-control {
    border-radius: 5px;
    min-height: 41px;
    background-color: #f6f6f6;
    margin-left: 0px;
}

.word-dashbord-right .header-dp .css-b62m3t-container .css-13f45xl-control {
    border-radius: 5px;
    height: 40px;
    background-color: #fff;
    margin-left: 0px;
    border-color: #dee2e6;
}

.word-dashbord-right .header-dp .css-b62m3t-container .css-1a5f8b4-control {
    border-radius: 5px;
    min-height: 40px;
    background: #ffffff;
}

.css-1xc3v61-indicatorContainer svg {
    display: none;
}

.css-15lsz6c-indicatorContainer svg {
    display: none;
}

.rs-dropdownImg {
    position: absolute;
    top: 17px;
    right: 14px;
}

.lesson-forms .rs-dropdownImg {
    right: 25px;
}

.lesson-forms-selectors .rs-dropdownImg {
    display: none;
}

.lesson-forms .css-1a5f8b4-control {
    background-color: #F6F6F6;
}

.lesson-forms-selectors .css-1a5f8b4-control {
    background-color: #F6F6F6;
    min-height: 40px;
    border-radius: 5px;
}

.css-12a83d4-MultiValueRemove:hover {
    background-color: #fbab40 !important;
    color: #40474d !important;
}

.lesson-forms-selectors .css-1p3m7a8-multiValue {
    margin: 0px 0px 4px 2px;
}

.lesson-forms-selectors .css-3w2yfm-ValueContainer {
    overflow: hidden;
    padding: 4px 0px 0px 2px;
    flex-wrap: nowrap;
}

.lesson-forms-selectors .css-13cymwt-control {
    background: #f6f6f6;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
    min-width: 236px;
}

.lesson-forms-selectors .css-t3ipsp-control {
    background: #f6f6f6;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
    min-width: 236px;
}

.lesson-forms .css-1a5f8b4-control {
    margin: 0px !important;
}

.lesson-forms .css-13f45xl-control {
    margin: 0px !important;
}

.close-btn {
    z-index: 1;
}

.syl-parts span {
    display: inline !important;
    font-size: 22px !important;
    font-weight: 700 !important;
}

.complete-lesson .completeBtn {
    background: #61B0DC;
}

.issueLesson span {
    margin-top: 25px;
}

.spinner-main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
    position: fixed;
    background: rgb(0 0 0 / 50%);
    margin: 0 auto;
    top: 0px;
    left: 0px;
}

    .spinner-main img {
        width: 70px;
    }

.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.fade-out {
    opacity: 0.4;
    transition: opacity 0.5s ease-in-out;
}

.terminology {
    margin-top: -70px;
    justify-content: center !important;
    width: 100%;
}

.whiteBox-heading.terminology {
    padding-top: 10px !important;
    padding-bottom: 10px;
}

    .whiteBox-heading.terminology h2 {
        font-size: 28px;
        width: 360px;
    }

.wordDashbord-LanguagesForms .worddashbord-dropdown-right {
    margin: 6px 0px 0px 11px;
}

.worddashbord-dropdown-right .dropdown-menu {
    border-radius: 10px;
    left: -174px !important;
    top: 7px !important;
    box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.2);
    padding: 17px 8px;
}

    .worddashbord-dropdown-right .dropdown-menu .dropdown-item {
        font-size: 14px;
        font-weight: 600;
        color: #40474D;
    }

.worddashbord-dropdown-right .dropdown-toggle::after {
    display: none;
}

.dynamic-flags {
    position: absolute;
    top: 5px;
    left: 11px;
    border-radius: 50%;
}

.wordDashbord-LanguagesForms .input-field-left {
    width: 100%;
    position: relative;
}

.wordDashbord-LanguagesForms .lesson-forms .form-image {
    padding-left: 46px;
}

.steps-main {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.step-one h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}

.step-one .start-button-two {
    margin: 0 auto;
}

.start-button {
    background: #61B0DC;
    padding: 10px 40px;
    margin: 9px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    text-transform: capitalize;
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.step-two h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 35px;
}

.step-two span {
    font-size: 16px;
    font-weight: 700;
    background-color: #E3E38F;
    padding: 5px 12px;
}

.step-three a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background: #EF4536;
    border-radius: 50%;
    margin: 0 auto;
}

.step-three span {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 3px;
}

.step-three h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}

.syllable-learning-main {
    overflow-x: auto;
}

.syllable-scroll {
    display: flex;
}

.syllable-left {
    text-align: left;
    width: 20%;
    padding-top: 30px;
}

    .syllable-left span {
        font-weight: 700;
        display: block;
        margin-bottom: 61px;
    }

.syllable-right {
    background: #EAEAEA;
    width: 80%;
    border-radius: 5px;
    text-align: left;
    padding: 20px;
    position: relative;
}

.blur-section {
    filter: blur(4px);
}

.syllable-flag-top {
    margin-bottom: 10px;
}

    .syllable-flag-top img {
        border-radius: 50%;
    }

.syllable-flag-bottom img {
    border-radius: 50%;
}

.syllable-flag-bottom {
    opacity: 0.5;
    margin-bottom: 20px;
}

.step-one .start-button-two {
    margin: 0 auto;
}

.syllable-flag-main {
    margin-bottom: 20px;
}

.syllable-flag-top span {
    padding: 4px 5px;
    margin-left: 7px;
    font-weight: 700;
}

.syllable-flag-bottom span {
    background-color: #E3E38F;
    padding: 4px 10px;
}

.stop-btn-small {
    margin-top: 30px;
}

.lesson-amazingly {
    justify-content: flex-start;
    width: unset;
}

.lang-main {
    margin-top: 20px;
}

.start-center {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.word-dashbord-right .lesson-forms .fullword {
    padding-left: 47px;
}

.white-loading-Speechbox {
    width: 71.5%;
    height: 102px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 30px 20px 30px 20px;
    border-radius: 5px;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
}

    .white-loading-Speechbox h2 {
        text-shadow: none !important;
        color: #40474D !important;
        font-size: 16px;
        font-weight: 400;
    }

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    background-color: #2F353D;
    color: #fff;
    padding: 1px 5px 1px 5px;
    border-radius: 5px;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
    color: #fff;
    font-size: 12px;
}

.arrow-down {
    position: absolute;
    top: 94%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
}

.tooltip-content {
    /* Add styles for the tooltip content */
}

.tooltip-container:hover .tooltip {
    opacity: 1;
}
/*Dashbord Multiselect Design*/
.css-1nmdiq5-menu div {
    background: #fff;
}

    .css-1nmdiq5-menu div div:hover {
        background: #dcd6d6;
    }

    .css-1nmdiq5-menu div div:not(:last-child) {
        border-bottom: 1px solid #dcdcd6;
    }

.css-t3ipsp-control:hover {
    border-color: #fff !important;
}

.css-13cymwt-control:active {
    border: 1px solid #fff;
}

.css-cnp1l-MenuPortal {
    z-index: 9;
}

.form-control:focus {
    border-color: #CDCDCD;
    box-shadow: none;
}

.difficulty-dp .css-1a5f8b4-control {
    border: 1px solid #cdcdcd;
    min-height: 40px;
    border-radius: 5px;
}

.production-image h2 {
    margin-right: 10px;
}

.red-text {
    color: red !important;
}

[class*="MenuPortal"] {
    z-index: 9;
}

.instructions-main {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 30px;
}

.step-one {
    text-align: center;
}

.step-two {
    text-align: center;
}

    .step-two h2 {
        margin-bottom: 21px !important;
    }

.step-one span {
    border-radius: 50%;
    max-width: unset;
    height: 80px;
    width: 80px;
    font-size: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step-three .red-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background: #EF4536;
    border-radius: 50%;
    margin: 0 auto;
}

.step-three .white-block {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 3px;
}

.sticky-right {
    overflow-y: auto;
    position: sticky;
    top: 72px;
}

    .sticky-right::before {
        content: "";
        width: 21px;
        height: 100%;
        background: #f6f6f6;
        display: inline-block;
        position: absolute;
        right: 0px;
        top: 0px;
    }

.sticky-scroll {
    height: calc(100vh - 70px);
    overflow-y: auto;
}

.whitebox-headingwidth h2 {
    width: auto !important;
}

.lesson-complete {
    padding-top: 15px;
}

.userlesson-terminology .whiteBox-heading {
    display: unset;
}

.userlesson-terminology .usa-flagg {
    display: flex;
}

    .userlesson-terminology .usa-flagg .tooltip-container {
        margin-right: 15px;
    }

.phonetic-main .form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
    min-height: 40px;
    height: 40px;
    line-height: 1.25;
}

.phonetic-main .form-floating > label {
    top: -6px;
    font-size: 14px;
}

.lesson-forms .form-floating > .form-control {
    min-height: 40px;
}

.lesson-forms .form-floating > label {
    top: -6px;
}

.sentence .form-floating > label {
    top: -12px;
}

.sentence {
    margin-top: 5px;
}

.phonetic-main .form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
    min-height: 40px;
    height: 40px;
    line-height: 1.25;
}

.phonetic-main .form-floating > label {
    top: -6px;
    font-size: 14px;
}
/*Universal Class for removing shadow from focus on floating label*/
.form-control:focus {
    color: #40474d;
    background-color: var(--bs-body-bg);
    border-color: #dee2e6;
    outline: 0;
    box-shadow: none;
}
/*Universal Class for removing shadow from focus on floating label*/
.phonetic-main .text-box {
    margin: 20px 0px;
}

    .phonetic-main .text-box .form-Textarea {
        min-height: 120px;
        height: 120px;
        line-height: 1.25;
        resize: none;
    }

.wordDashbord-LanguagesForms {
    margin-top: 20px;
}

    .wordDashbord-LanguagesForms .lesson-forms label {
        margin-bottom: 20px;
        margin-top: 5px;
    }
    /* .wordDashbord-LanguagesForms .lesson-forms .form-control::placeholder {
   color: #40474D;
} */
    .wordDashbord-LanguagesForms .amaz-heading {
        font-size: 18px;
        color: #000;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .wordDashbord-LanguagesForms .lesson-forms .form-image {
        padding-left: 46px;
    }
/*React Dropdown Fix For Index Page*/
/*[class*="MenuPortal"] {
    z-index: 9999 !important;
    border-color: 2px solid #000 !important;
}
*/
.css-lex2sk-MenuPortal {
    z-index: 9999 !important;
}

.wordDashbord-LanguagesForms .lesson-forms {
    display: flex;
}

.dropdown-selector .css-1a5f8b4-control {
    background-color: #ffffff;
    height: 45px;
    border: 2px solid #cdcdcd;
}

.wordDashbord-LanguagesForms .input-field-left {
    width: 100%;
    position: relative;
}

.css-1yf1y0z-placeholder {
    display: flex;
    margin-left: 15px;
}

.wordDashbord-LanguagesForms .input-field-left .form-margin {
    margin-bottom: 10px;
}

.dropdown-selector .css-1a5f8b4-control:hover {
    /*  border-color: hsl(0, 0%, 70%);*/
    border-color: #cdcdcd;
}

.wordDashbord-LanguagesForms .custom-select .form-selector {
    margin-bottom: 10px;
}

.dropdown-selector .css-b62m3t-container .css-13f45xl-control {
    border-radius: 5px;
    min-height: 41px;
    background-color: #fff;
    margin-left: 0px;
    height: 45px;
}

.worddashbord-dropdown-right .lesson-forms-selectors .form-select {
    /*background: url(../images/drop-down.svg) no-repeat !important;*/
    background-position: 91% 16px !important;
    background-color: #fff !important;
}

.dropdown-selector .css-b62m3t-container .css-13f45xl-control:hover {
    border: 2px solid #cdcdcd;
}

.worddashbord-dropdown-right .lesson-forms-selectors .form-select:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
    min-width: 150px;
}

.worddashbord-dropdown-right :nth-child(1) {
    margin-bottom: 10px;
}

.wordDashbord-LanguagesForms .worddashbord-dropdown-right {
    margin: 0px 0px 0px 11px;
}

.worddashbord-dropdown-right .dropdown-menu {
    border-radius: 10px;
    left: -174px !important;
    top: 7px !important;
    box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.2);
    padding: 17px 8px;
}

    .worddashbord-dropdown-right .dropdown-menu .dropdown-item {
        font-size: 14px;
        font-weight: 600;
    }

.custom-select {
    width: 21%;
    height: 40px;
}

.dropdown-selector .css-1fdsijx-ValueContainer {
    padding: 2px 0px 2px 19px;
}

.custom-select select {
    color: #40474d;
    border-radius: 5px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
}

.a-plus-middle {
    text-align: center;
}

.dynamic-flags {
    position: absolute;
    top: 5px;
    left: 11px;
}

.dynamic-flags-two {
    position: absolute;
    top: 54px;
    left: 11px;
}

.blury-heading {
    margin: 8px 0px 20px 11px !important;
    opacity: .7;
}

.worddashbord-dropdown-right .lesson-forms-selectors .form-select:focus {
    border-color: #DEE2E6;
    outline: 0;
    box-shadow: none;
}

.sentence-div {
    margin-top: 20px;
}

.a-plus-middle {
    text-align: center !important;
    margin-left: 15px;
    margin-right: 15px;
    width: 50%;
}

.new-lesson-start .whiteBox-para {
    max-width: 291px;
    line-height: 21px !important;
}

.tooltip-space {
    display: inline-block;
    margin: 5px 10px 0px 0px;
}
/*.index-flag-fix .css-1fdsijx-ValueContainer .css-qav5hl-singleValue {
    text-align: left;
}

    .index-flag-fix .css-1fdsijx-ValueContainer .css-qav5hl-singleValue span {
        padding-left: 47px;
    }

    .index-flag-fix .css-1fdsijx-ValueContainer .css-qav5hl-singleValue img {
        left: 17px;
        bottom: 0;
        width: 27px !important;
    }
*/
.ticking-percent {
    position: absolute;
    left: -40px;
    font-weight: 600;
}

.p-20 {
    padding-top: 20px !important;
}
/*Latest Fixes nov 15 2023*/
.my-lessons-dropdown a img {
    margin-top: -4px;
}

.my-lessons-dropdown a .dashbord-ico {
    min-width: 25px;
    padding-top: 6px;
    padding-right: 1px;
}

.join-lesson img {
    min-width: 25px;
    padding-left: 5px;
}

.step-three .white-block {
    width: 38px;
    height: 38px;
}

.circle-arrows a img {
    min-width: 22px;
    padding-left: 0;
    padding-right: 3px;
}

.production-right .circle-arrows {
    padding: 0px 0px 0px 4px;
}

.editt a img {
    min-width: 27px;
}

.dell a img {
    min-width: 25px;
}

.lesson-del-button a img {
    min-width: 25px;
    padding-bottom: 1px;
}

.lesson-parabox-notsaved {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0px 10px;
}

    .lesson-parabox-notsaved span {
        color: #40474D;
        font-size: 12px;
        font-weight: 600;
    }

.word-notsave-icon {
    position: relative;
}

    .word-notsave-icon .focus-saved {
        position: absolute;
        bottom: 10px;
        left: 15px;
    }

.invalid-input {
    border: 2px solid red;
}

.blur-bg {
    filter: blur(5px);
}
/* word dashbord checkbox */
.word-dashbord-checkbox label {
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    margin: 6px 0px 0px 10px;
}

.word-borderLeft {
    border-left: 1px solid rgb(255 255 255 / 25%);
    padding-left: 66px;
}
/* Autocomplete.css */
.autocomplete-dropdown {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 1;
}

.autocomplete-item {
    cursor: pointer;
    margin-left: 15px;
}

    .autocomplete-item:hover {
        background-color: #f0f0f0;
    }
/* Lessons page */
.table-main .table-responsiveness .table-inside table tbody tr:nth-child(even) {
    background-color: #d4d4d4;
}

.table-main .table-responsiveness .table-inside tr td {
    padding: 10px 25px 10px 25px !important;
}

.table-main .table-responsiveness .table-inside {
    padding: 25px 0px 0px 0px;
}

    .table-main .table-responsiveness .table-inside tr th {
        padding-bottom: 25px;
    }

    .table-main .table-responsiveness .table-inside span {
        margin-left: 0px;
        width: 80%;
        max-width: 490px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 590px;
        vertical-align: middle;
    }
/* full screen blur stats here */
.full-screen-blur-main img {
    position: fixed;
    top: 7vh;
    left: 41vh;
    z-index: 11;
}

.full-screen-blur {
    backdrop-filter: blur(4px);
    filter: blur(20px);
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 100%;
    z-index: 10;
    background: #00000069;
    /* for microsoft edge */
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    -webkit-filter: blur(20px);
    filter: blur(20px);
    /* for chrome browser */
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    -webkit-filter: blur(20px);
    filter: blur(20px);
    /* for Mozilla Firefox */
    backdrop-filter: blur(4px);
    filter: blur(20px);
    /* For Opera */
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    -webkit-filter: blur(20px);
    filter: blur(20px);
    /* For Safari */
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    -webkit-filter: blur(20px);
    filter: blur(20px);
}
/* pagination left */
.paginaion-left {
    justify-content: flex-start;
}
/* Language Training Page starts here */
.lang-training-main .table-inside tr th {
    font-weight: 700;
    color: #000;
    padding-bottom: 20px;
}

    .lang-training-main .table-inside tr th:nth-child(3) {
        width: 14.2%;
    }

    .lang-training-main .table-inside tr th:nth-child(4) {
        text-align: center;
        width: 13%;
        border-right: 1px solid #0000004d;
    }

.lang-training-main .table-inside tr td:nth-child(4) {
    text-align: center;
    border-right: 1px solid #0000004d;
}

.lang-training-main .table-inside tr th:nth-child(6) {
    text-align: center;
}

.lang-training-main .table-inside tr th:nth-child(7) {
    padding-left: 50px;
}

.lang-training-main .table-inside tr td:nth-child(7) {
    padding-left: 50px;
}

.lang-training-main .table-inside tr td:nth-child(6) {
    text-align: center;
}

.lang-training-main .table-inside tr th:nth-child(1) {
    padding-left: 0px;
}

.lang-training-main .table-inside tr th:nth-child(5) {
    width: 15%;
    text-align: center;
}

.lang-training-main .table-inside tr td:nth-child(5) {
    text-align: center;
}

.lang-training-main .table-inside tr th:last-child {
    text-align: center;
}

.lang-training-main .table-inside tr td:last-child {
    text-align: center;
}

.lang-training-main .table-inside tbody tr:last-child td {
    padding-bottom: 0px;
}

.lang-training-main tr th:nth-child(2) {
    text-align: center;
    width: 15%;
}

.lang-training-main tr td:nth-child(2) {
    text-align: center;
}

.lang-training-main .table-inside tr th:nth-child(1) {
    width: 14%;
}

.lang-training-main .table-inside tbody tr:first-child td {
    padding-top: 0px;
}

.lang-training-main .table-inside tr td {
    padding-bottom: 20px;
}

.lang-training-main .table-inside::before {
    border-bottom: 0px solid rgb(0 0 0 / 30%);
}

.lang-training-main .table-inside {
    padding: 25px 35px 25px 30px;
}

    .lang-training-main .table-inside tr td {
        width: unset;
    }

        .lang-training-main .table-inside tr td a {
            background: unset;
            width: unset;
            height: unset;
            display: unset;
            justify-content: unset;
            align-items: unset;
            padding: unset;
            border-radius: unset;
            margin-left: unset;
            color: #F67D3C;
        }
/* language training bars code */
.language-training-form .lessons-input-fielsMain {
    margin-top: 20px;
    margin-bottom: 20px;
    position: unset;
    top: unset;
    z-index: 99999;
    background: unset;
    padding: unset;
}

.language-training-form .lesson-forms {
    width: 23.7% !important;
}

.language-training-form .clear-results {
    display: flex;
    align-items: center;
}

    .language-training-form .clear-results a {
        font-size: 12px;
        font-weight: 700;
        color: #F15538;
    }
/* language training 2nd table */
.lang-training-main-two {
    margin: 0px 0px 20px 0px;
}

.play-button-blue {
    background: #61B0DC !important;
    width: 40px !important;
    height: 40px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 12px 12px !important;
    border-radius: 50% !important;
    margin: 0 auto !important;
}

.lang-tick-exclamation {
    display: flex;
    justify-content: center;
}

.red-exclamation {
    background: #EF4536;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 5px;
}

.green-tick {
    background: #3CA34C;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 5px;
}

.lang-training-main-two .table-inside tr th:nth-child(4) {
    text-align: left;
    width: 22%;
    border-right: 0px solid #0000004d !important;
}

.lang-training-main-two .table-inside tr td:nth-child(4) {
    text-align: left;
    width: 25%;
    border-right: 0px solid #0000004d !important;
}

.lang-training-main-two .table-inside tr th:nth-child(1) {
    width: 24%;
}

.lang-training-main-two tr th:nth-child(2) {
    text-align: left;
    width: 14%;
}

.lang-training-main-two tr td:nth-child(2) {
    text-align: left;
}

.lang-training-main-two .table-inside tr th:nth-child(3) {
    width: 9.2%;
}

.lang-training-main-two .table-inside::before {
    border-bottom: 1px solid rgb(0 0 0 / 30%);
}

.lang-training-main-two .table-inside tr th {
    padding-bottom: 40px;
}

.lang-training-main.lang-training-main-two .table-inside tbody tr td:first-child {
    padding-left: 35px !important;
}

.lang-training-main-two .table-inside tr td:nth-child(4) {
    padding-left: 5px !important;
}

.lang-training-main-two table tbody tr:nth-child(even) {
    background-color: #D4D4D4;
    padding-left: 35px !important;
}

.lang-training-main-two .table-inside {
    padding: 25px 0px 25px 0px;
}

    .lang-training-main-two .table-inside tr td {
        padding-left: 35px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

.three-more {
    color: #61B0DC !important;
    margin-left: 10px !important;
}

.red-exclamation a {
    border: none;
}

.lang-training-main-two .btn:first-child:active {
    border: 0px solid #fbab40 !important;
    background-color: unset !important;
}
/* Language training Modal */
.Lang-training-modal .modal-dialog {
    max-width: 1256px;
}

.Lang-training-modal .modal-body {
    display: flex;
    padding: 40px 40px;
    /* gap: 70px; */
}

.Lang-training-modal .modal-header {
    border: none;
}

.Lang-training-modal .lang-btn-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.lang-btn-container .play-button-blue {
    margin: unset !important;
}

.Lang-training-modal .lang-btn-container .lang-floopy {
    width: 40px;
    height: 40px;
    background: #3CA34C;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.Lang-training-modal .modal-body p {
    font-size: 16px;
    color: #40474D;
    font-weight: 600;
}

.yellow-two {
    color: #FBAB40;
}

.lang-training-left {
    padding-right: 40px;
}

.lang-training-right {
    border-left: 1px solid #0000004d;
    padding-left: 40px;
}

.red-cross {
    width: 40px;
    height: 40px;
    background: #EF4536;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.popover-bg {
    display: flex;
    gap: 25px;
    padding: 15px 15px 15px 15px;
    background: #2F353D;
    border-radius: 5px;
    max-width: 350px;
    position: relative;
}

.close-tick {
    display: flex;
    gap: 5px;
}

.popover-bg .lesson-forms {
    width: 64%;
}

.popover-bg .polygone-shape {
    position: absolute;
    bottom: -12%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.popover-content {
    display: none;
    /* Other styles for the popover */
}
/* Language Training pagination */
.language-training-pagination .pagination-main {
    justify-content: flex-start;
}

.table-main .table-play-btn {
    min-width: 21px;
    padding-left: 3px
}

.table-main .table-image {
    margin-right: 15px;
}

.new-lesson-dashboard .lesson-left-flag {
    display: unset;
}

.new-lesson-dashboard .language-difficulty .tooltip-container img {
    margin-left: 0;
    margin-top: 10px;
}

.dasboard-plusico {
    min-width: 24px;
    padding-right: 1px;
}

.suggested-fix .lesson-left-flag img {
    margin: 5px 15px 0px 1px;
}
/*SignUp Native Language Drop Down*/
body > div:last-child {
    z-index: 9999 !important;
}

.form-main form index-flag-fix .language-flag-signup {
    right: unset !important;
    left: 21px !important;
    top: 0px !important;
    width: 28px !important;
}

.index-flag-fix span.language-span-signup.selected {
    margin-left:40px !important;
    font-size: 16px !important;
}

.lesson-languages-main .difficulty-dp .language-flag-signup {
    right: unset !important;
    left: 21px !important;
    top: 0px !important;
    width: 28px !important;
}

span.language-span-signup.selected {
    margin-left: 0px !important;
    font-size: 14px !important;
}

.form-main form index-flag-fix .language-span-signup {
    position: absolute !important;
    left: 60px !important;
    bottom: 2px !important;
}

img.language-flag-signup.selected {
    bottom: 0px !important;
    left: 13px;
    height: 28px;
    margin-right: auto !important;
    width: 28px !important;
    border-radius: 100px;
}

.difficulty-dropdown.header-dp.new-language-selected .css-qav5hl-singleValue > img.language-flag-signup.selected + span.language-span-signup.selected {
    margin-left: 5px !important;
}
/*SignUp Native Language Drop Down ends*/
/* Clear Filters Anchor */
.all-lessons .clear-filters a {
    margin-left: 15px;
    font-size: 12px;
    font-weight: 700;
    color: #F15538;
}

.dashbord-main .clear-filters a {
    margin-left: 15px;
    font-size: 12px;
    font-weight: 700;
    color: #F15538;
}

.dashbord-main dashbord-lessons-main .lessons-input-fielsMain .clear-filters {
    display: flex;
    align-items: center;
    margin: 8px;
}
/* Clear Filters Anchor ends*/
.lesson-header-main-userlesson {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.difficulty-dots {
    margin-bottom: 4px;
}

/*2/29/2024* fixes*/

.table-main .table-inside table tr th:nth-child(1) {
    padding-left: 33px;
}

.table-main .table-responsiveness .table-inside tr td:nth-child(2) {
    padding: 10px 12px 10px 2px !important;
}

.table-main .table-responsiveness .table-inside tr td:nth-child(3) {
    padding: 10px 25px 10px 2px !important;
}

.lesson-paragraph-main {
    width: unset;
}

.sticky-right::before {
    display: none;
}

.sticky-scroll {
    height: unset;
    overflow-y: unset;
}

/* New Lesson Card 2/29/2024*/
.terminology-mainnn {
    background: #F6F6F6;
    border-radius: 15px;
    margin-bottom: 30px;
    word-break: break-word;
}

    .terminology-mainnn .country-flagg {
        width: 100%;
    }

.work-terminology-text {
    padding: 20px 20px 0px 20px;
    min-height: 163px;
}

    .work-terminology-text h2 {
        font-weight: 700;
        font-size: 22px;
        color: #000000;
        min-height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .work-terminology-text p {
        color: #40474D;
        font-weight: 400;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

.image-top-box {
    position: relative;
}

    .image-top-box .diffculty-level {
        position: absolute;
        top: 17px;
        right: 20px;
    }

.strt-leson {
    background: #61B0DC;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

    .strt-leson span {
        color: #fff;
        font-size: 16px;
        text-decoration: none;
        font-weight: 700;
    }

.card-link {
    text-decoration: none;
    color: inherit;
}

    .card-link:hover {
        text-decoration: none;
    }

.suggested-fix .my-lessons-dropdown a img {
    margin-top: 0px;
    margin-right: 1px;
}

.suggested-fix {
    margin-top: -25px;
}

.word-dashbord-unique .my-lessons-dropdown a img {
    margin-top: 0px;
}

.lesson-admin-unique .my-lessons-dropdown a img {
    margin-top: 0px;
}

.suggested-lesson-unique .suggested-fix .dropdown-toggle img {
    min-width: 24px;
    padding-right: 1px;
}

.error-mesg-main {
    text-align: center;
}

.del-box h2 {
    font-weight: 700;
    font-size: 28px;
    color: #000000;
    padding: 30px 0px 20px 0px;
    margin-bottom: 0;
}

.del-box span {
    font-weight: 700;
    font-size: 20px;
    color: #000;
    max-width: 303px;
    display: inline-block;
    margin-bottom: 40px;
}

.error-message-mainbox .modal-dialog {
    max-width: 500px;
}

.continoue {
    background-color: #61B0DC;
    margin: 0px 45px 45px 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 8px 0px;
}

    .continoue a {
        font-weight: 700;
        font-size: 16px;
        color: #fff;
        text-decoration: none;
    }



/* Language Training Page starts here */
.lesson-fail-rate-main tr th {
    font-weight: 700;
    color: #000;
}

    .lesson-fail-rate-main tr th:nth-child(3) {
        width: 14.2%;
        width: 250px;
        max-width: 250px;
        min-width: 250px;
    }

    .lesson-fail-rate-main tr th:nth-child(4) {
        text-align: center;
        width: 150px;
        border-right: 1px solid #0000004d;
    }

.lesson-fail-rate-main tr td:nth-child(4) {
    text-align: center;
    border-right: 1px solid #0000004d;
}

.lesson-fail-rate-main tr th:nth-child(6) {
    text-align: center;
}

.lesson-fail-rate-main tr th:nth-child(7) {
    padding-left: 50px;
}

.lesson-fail-rate-main tr td:nth-child(7) {
    padding-left: 50px;
}

.lesson-fail-rate-main tr td:nth-child(6) {
    text-align: center;
}

.lesson-fail-rate-main tr th:nth-child(1) {
    padding-left: 35px;
    padding-left: 35px;
    width: 250px;
    max-width: 250px;
    min-width: 250px;
}

.lesson-fail-rate-main tr:first-child th {
    padding-bottom: 10px;
}

.lesson-fail-rate-main tr td:nth-child(1) {
    padding-left: 35px;
}

.lesson-fail-rate-main tr th:nth-child(5) {
    text-align: center;
    width: 150px;
}

.lesson-fail-rate-main tr td:nth-child(5) {
    text-align: center;
}

.lesson-fail-rate-main tr th:last-child {
    text-align: center;
}

.lesson-fail-rate-main tr td:last-child {
    text-align: center;
}

.lesson-fail-rate-main tbody tr:last-child td {
    padding-bottom: 0px;
}

.lesson-fail-rate-main tr th:nth-child(2) {
    text-align: center;
    width: 125px;
    display: inline-block;
}

.lesson-fail-rate-main tr td:nth-child(2) {
    text-align: center;
}

.lesson-fail-rate-main tr th:nth-child(1) {
    /*  width: 14%;*/
    min-width: 134px;
}

.lesson-fail-rate-main tbody tr:first-child td {
    padding-top: 10px;
}

.lesson-fail-rate-main tr td {
    padding-bottom: 20px;
}

.lesson-fail-rate-main ::before {
    border-bottom: 0px solid rgb(0 0 0 / 30%);
}

.lesson-fail-rate-main {
    padding: 25px 35px 25px 30px;
}

    .lesson-fail-rate-main tr td {
        width: unset;
    }

        .lesson-fail-rate-main tr td a {
            background: unset;
            width: unset;
            height: unset;
            display: unset;
            justify-content: unset;
            align-items: unset;
            padding: unset;
            border-radius: unset;
            margin-left: unset;
            color: #F67D3C;
            text-decoration: none;
        }

.table-fail-rate-bg {
    background: #f6f6f6;
    border-radius: 10px;
    padding: 25px 0px 25px 0px;
}

.tab-main-2-span span {
    width: auto !important;
}

.table-box-left {
    display: flex;
    width: 60%;
    padding-right: 35px;
}

.table-box-right {
    display: flex;
    width: 45%;
    padding-left: 25px;
    border-left: 1px solid #ccc;
}

.tax-fail-bg {
    display: flex;
}

/* CSS STARTS HERE */
.popover-content-main {
    background: #40474D;
    display: flex;
    border-radius: 5px;
    max-width: 350px;
    padding: 15px;
    gap: 24px;
    position: relative;
}

    .popover-content-main .lesson-forms {
        width: 64.4%;
    }

.polygone-shape-one {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -5px;
}

.stop-btn.player {
    width: 40px;
    height: 40px;
}

.lesson-name-length-setting {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.word-dashboard-dropdown-scroll {
    max-height: 135px;
    overflow-y: auto;
}

.suggested-main-body {
    display: flex;
}

.add-suggested-popup-changes .suggested-main-left {
    width: 50%;
    padding-right: 40px;
}

.add-suggested-popup-changes .suggested-main-right {
    width: 50%;
    padding-left: 30px;
    border-left: 1px solid rgb(0 0 0 / 30%);
    padding-top: 50px;
}

.add-suggested-popup-changes .modal-dialog {
    max-width: 1255px !important;
}

.add-suggested-popup-changes .suggested-main-left .lesson-left-flag img {
    margin: 0px 10px 0px 10px;
    border-radius: unset;
}

.lesson-title-leftt button {
    border: none;
    background-color: #F6F6F6;
}

.lesson-title-leftt h2 {
    text-align: left;
    width: 80%;
}

.lesson-left-flag {
    display: flex;
    align-items: center;
}

.sugges-main-right-boxes {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.suggested-main-right .sugges-main-right-boxes:not(:last-child) {
    /*margin-bottom: 20px;*/
    border-bottom: 1px solid #acacac;
    padding: 26px 0px;
}

.lesson-left-flag img {
    margin: 0px 10px 0px 10px;
    border-radius: unset;
}

.suggested-bubble-count {
    width: 20px;
    height: 20px;
    background-color: #61B0DC;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50%;
    padding-top: 5px;
    margin-right: 15px;
    font-weight: 700;
    font-size: 11px;
}

.view-lesson-icon {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 25px;
    text-decoration: none;
}
    .view-lesson-icon .view-lesson-left {
        width: 40px;
        height: 40px;
        background: #61B0DC;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
        .view-lesson-icon .view-lesson-left img {
            width: 22px;
            height: 18px;
        }
    .view-lesson-icon .view-lesson-right a {
        color: #61B0DC;
        font-size: 18px;
        font-weight: 700;
        text-decoration: none;
    }

.lesson-admin-unique .suggested-lessons {
    margin-bottom: 15px;
}

.sugges-main-right-boxes .lesson-title-leftt button:focus-visible {
    outline: none !important;
}
/*May 15 2024 work*/
.lesson-description-main .css-18c3gx8-control {
    border-radius: 5px;
}

.lesson-description-main .focus-right .rs-dropdownImg {
    position: absolute;
    top: 233px;
    right: 12px;
}

.error-message-mainbox .continoue {
    margin: 0px 0px 0px 0px;
}

.error-message-mainbox .error-summary {
    display: flex;
    justify-content: center;
}

.error-message-mainbox .error-summary-two {
    text-align: left;
}

.error-message-mainbox .modal-body p {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}

.error-message-mainbox .del-box h2 {
    padding: 25px 0px 10px 0px;
}

.index-flag-fix .css-qav5hl-singleValue {
    text-align: left;
}
.dashbord-textarea {
    caret-color: black; 
}
.popup-model-text{
    color: red !important;
    font-size: 16px !important;
    margin-bottom: 15px !important;
}
.caret-black::placeholder {
    color: black;
}

.caret-black {
    caret-color: black;
}

.clear-filters {
    padding-top: 4px;
}